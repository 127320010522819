@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,300,400,700);

/* You can add global styles to this file, and also import other style files */
/* #GOOGLE FONT */
.ng-select.ng-select-opened>.ng-select-container{background:#fff;border-color:#b3b3b3 #ccc #d9d9d9}
.ng-select.ng-select-opened>.ng-select-container:hover{box-shadow:none}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow{top:-2px;border-color:transparent transparent #999;border-width:0 5px 5px}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover{border-color:transparent transparent #333}
.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container{border-bottom-right-radius:0;border-bottom-left-radius:0}
.ng-select.ng-select-opened.ng-select-top>.ng-select-container{border-top-right-radius:0;border-top-left-radius:0}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{border-color:#007eff;box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 0 3px rgba(0,126,255,0.1)}
.ng-select.ng-select-disabled>.ng-select-container{background-color:#f9f9f9}
.ng-select .ng-has-value .ng-placeholder{display:none}
.ng-select .ng-select-container{color:#333;background-color:#fff;border-radius:4px;border:1px solid #ccc;min-height:36px;align-items:center}
.ng-select .ng-select-container:hover{box-shadow:0 1px 0 rgba(0,0,0,0.06)}
.ng-select .ng-select-container .ng-value-container{align-items:center;padding-left:10px}
[dir="rtl"] .ng-select .ng-select-container .ng-value-container{padding-right:10px;padding-left:0}
.ng-select .ng-select-container .ng-value-container .ng-placeholder{color:#999}
.ng-select.ng-select-single .ng-select-container{height:36px}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{top:5px;left:0;padding-left:10px;padding-right:50px}
[dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{padding-right:10px;padding-left:50px}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value{background-color:#f9f9f9;border:1px solid #e6e6e6}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label{padding:0 5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container{padding-top:5px;padding-left:7px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container{padding-right:7px;padding-left:0}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{font-size:.9em;margin-bottom:5px;background-color:#ebf5ff;border-radius:2px;margin-right:5px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{margin-right:0;margin-left:5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled{background-color:#f9f9f9}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label{padding-left:5px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label{padding-left:0;padding-right:5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label{display:inline-block;padding:1px 5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon{display:inline-block;padding:1px 5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover{background-color:#d1e8ff}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{border-right:1px solid #b8dbff}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{border-left:1px solid #b8dbff;border-right:none}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right{border-left:1px solid #b8dbff}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right{border-left:0;border-right:1px solid #b8dbff}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{padding:0 0 3px 3px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{padding:0 3px 3px 0}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{top:5px;padding-bottom:5px;padding-left:3px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{padding-right:3px;padding-left:0}
.ng-select .ng-clear-wrapper{color:#999}
.ng-select .ng-clear-wrapper:hover .ng-clear{color:#D0021B}
.ng-select .ng-spinner-zone{padding:5px 5px 0 0}
[dir="rtl"] .ng-select .ng-spinner-zone{padding:5px 0 0 5px}
.ng-select .ng-arrow-wrapper{width:25px;padding-right:5px}
[dir="rtl"] .ng-select .ng-arrow-wrapper{padding-left:5px;padding-right:0}
.ng-select .ng-arrow-wrapper:hover .ng-arrow{border-top-color:#666}
.ng-select .ng-arrow-wrapper .ng-arrow{border-color:#999 transparent transparent;border-style:solid;border-width:5px 5px 2.5px}
.ng-dropdown-panel{background-color:#fff;border:1px solid #ccc;box-shadow:0 1px 0 rgba(0,0,0,0.06);left:0}
.ng-dropdown-panel.ng-select-bottom{top:100%;border-bottom-right-radius:4px;border-bottom-left-radius:4px;border-top-color:#e6e6e6;margin-top:-1px}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child{border-bottom-right-radius:4px;border-bottom-left-radius:4px}
.ng-dropdown-panel.ng-select-top{bottom:100%;border-top-right-radius:4px;border-top-left-radius:4px;border-bottom-color:#e6e6e6;margin-bottom:-1px}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child{border-top-right-radius:4px;border-top-left-radius:4px}
.ng-dropdown-panel .ng-dropdown-header{border-bottom:1px solid #ccc;padding:5px 7px}
.ng-dropdown-panel .ng-dropdown-footer{border-top:1px solid #ccc;padding:5px 7px}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup{-webkit-user-select:none;-moz-user-select:none;user-select:none;padding:8px 10px;font-weight:500;color:rgba(0,0,0,0.54);cursor:pointer}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled{cursor:default}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked{background-color:#f5faff}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked{background-color:#ebf5ff;font-weight:600}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{background-color:#fff;color:rgba(0,0,0,0.87);padding:8px 10px}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked{color:#333;background-color:#ebf5ff}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label{font-weight:600}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{background-color:#f5faff;color:#333}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled{color:#ccc}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{padding-left:22px}
[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{padding-right:22px;padding-left:0}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{font-size:80%;font-weight:400;padding-right:5px}
[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{padding-left:5px;padding-right:0}
[dir="rtl"] .ng-dropdown-panel{direction:rtl;text-align:right}
body:not(.menu-on-top).desktop-detected {
  min-height: auto !important;
  overflow: hidden;
}
body {
  background: unset;
  background-color: #202124;
}
#content {
  padding: 20px !important;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}
#content::-webkit-scrollbar {
  display: none;
}
.page-footer {
  /* padding-left: 0px; */
  z-index: 0;
  border-top: none;
}
#logo {
  background-repeat: no-repeat;
  background-size: 100%;
  height: 49px !important;
  width: 135px;
  display: block;
  margin-top: 0px;
  margin-left: 36px;
}
.setnavBackgroundImg {
  position: relative;
  z-index: 2;
  max-width: none;
  width: 90px;
  height: 90px;
  cursor: pointer;
  background-repeat: no-repeat;
  display: flex;
  margin: 0 auto;
  height: 110px;
  width: 110px;
}
.col-xs-10 {
  width: 83.33333333% !important;
}
.col-xs-2 {
  width: 16.66666667% !important;
}
.col-xs-3 {
  width: 25% !important;
}
.col-xs-7 {
  width: 58.33333333% !important;
}
.col-xs-8 {
  width: 66.66666667% !important;
}
.col-xs-6 {
  width: 50% !important;
}
.col-xs-1 {
  width: 8.33333333% !important;
}
.col-xs-4 {
  width: 33.33333333% !important;
}
.col-xs-5 {
  width: 41.66666667% !important;
}
#extr-page {
  overflow-x: hidden;
}
#extr-page #header #logo {
  margin-left: 75px !important;
  margin-top: 10px !important;
}
#extr-page #header #logo img {
  width: 200px;
}
.notification-body .unread {
  background: #1E1E1E;
}
.notification-body li span {
  background: #0C1214 !important;
}
.li-footer__copy-text {
  color: #8B91A0;
}
.registeContent {
  max-height: calc(100vh);
}
.iom-logo {
  height: 40px;
  width: 120px;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: -6px;
}
.logoconfirmRegister {
  height: 100px;
  width: 200px;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: -15px;
}
.loader-outer {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}
.loader > span {
  width: 15px;
  height: 15px;
  display: block;
  background: #8B9635;
  border-radius: 50%;
  position: relative;
  margin: 0 5px;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dot-1 {
  animation: anim 1s linear 0s infinite;
}
.dot-2 {
  animation: anim 1s linear 0.25s infinite;
}
.dot-3 {
  animation: anim 1s linear 0.5s infinite;
}
.dot-4 {
  animation: anim 1s linear 0.75s infinite;
}
@keyframes anim {
  0% {
    top: 0;
  }
  50% {
    top: 15px;
  }
  100% {
    top: 0;
  }
}
.dataTables_info .select-item {
  padding: 5px;
}
@media (max-width: 1161px) and (min-width: 300px) {
  #extr-page #header #logo img {
    width: 150px;
  }

  #extr-page #header #logo {
    margin-left: 10px !important;
    margin-top: 20px !important;
  }
}
@media only screen and (max-width: 280px) {
  #header > :first-child,
aside {
    width: 150px;
  }

  #extr-page #header #logo img {
    width: 125px;
  }

  #extr-page #header #logo {
    margin-left: 10px !important;
    margin-top: 20px !important;
  }
}
.includePdf label {
  margin-bottom: 15px !important;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none !important;
}
/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-1 #logo-group {
  background: unset !important;
}
.smart-style-1 #logo {
  background-image: url(/assets/img/bexio-citrus.png);
}
.smart-style-1 .iom-logo {
  background-image: url(/assets/img/logo.svg);
}
.smart-style-1 .logoconfirmRegister {
  background-image: url(/assets/img/iomLogoWhite.svg);
}
.smart-style-1 .btn-header:first-child a {
  background: unset;
  border: 1px solid #e4e4e4;
}
.smart-style-1 #header {
  background-color: #202124;
}
.smart-style-1 aside {
  background-color: #171819;
  border-right: 1px solid #333333 !important;
}
.smart-style-1 .page-footer {
  background-color: #202124;
}
.smart-style-1 #ribbon {
  background: #707070;
}
.smart-style-1 .ajax-dropdown {
  background-color: #0C1214 !important;
}
.smart-style-1 .ajax-notifications {
  background-color: #0C1214 !important;
}
.smart-style-1 .header-dropdown-list a:hover {
  color: #ffffff !important;
}
.smart-style-1 .setnavBackgroundImg {
  background-image: url(/assets/img/svgImages/Group348.svg);
}
.smart-style-1 .ng-select.ng-select-opened > .ng-select-container {
  background: #0C1214 !important;
}
.smart-style-1 .ng-select .ng-select-container {
  background: #0C1214;
  color: #ffffff;
  font-size: 12px;
  border-radius: 10px !important;
  border: unset !important;
}
.smart-style-1 .ng-select.ng-select-opened > .ng-select-container {
  background: #0C1214 !important;
}
.smart-style-1 .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #202124 !important;
  color: #ffff;
  font-size: 12px;
}
.smart-style-1 .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color: #ffff !important;
}
.smart-style-1 .ng-dropdown-panel.ng-select-bottom {
  border: 1px solid #666666 !important;
}
.smart-style-1 .smart-form .checkbox input:checked + i {
  border: 2px solid #666666;
  border-radius: 5px;
}
/*
---------------------------------------------------------------------------------------
    End Dark theme style
---------------------------------------------------------------------------------------
*/
/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-2 #logo {
  background-image: url(/assets/img/bexio-citrus.png);
  margin-top: 2px;
}
.smart-style-2 .iom-logo {
  background-image: url(/assets/img/logoWhite.svg);
}
.smart-style-2 .logoconfirmRegister {
  background-image: url(/assets/img/iomLogoBlack.svg);
}
.smart-style-2 .login-info {
  color: #212529;
}
.smart-style-2 aside {
  color: #ffffff !important;
  background: #ededed !important;
}
.smart-style-2 #header {
  background-color: #ffffff;
}
.smart-style-2 .setnavBackgroundImg {
  background-image: url(/assets/img/svgImages/Group349.svg);
}
.smart-style-2 .ng-select .ng-select-container {
  background: #EEEEEE !important;
  border-radius: 10px !important;
  order: unset !important;
}
.smart-style-2 .smart-form .checkbox input:checked + i {
  border-color: #666666 !important;
}
/*
---------------------------------------------------------------------------------------
    White Dark theme style
---------------------------------------------------------------------------------------
*/
@media (max-width: 992px) {
  #content {
    max-height: calc(100vh - 130px) !important;
  }
}
@media (max-width: 376px) {
  #content {
    max-height: calc(100vh - 300px) !important;
  }
}
body:not(.menu-on-top).mobile-detected {
  min-height: auto !important;
  overflow: hidden;
}
.checkbox i {
  position: absolute;
  top: 3px;
  left: 0;
  display: block;
  width: 17px;
  height: 17px;
  outline: 0;
  background: transparent !important;
  border: 2px solid #666;
  border-radius: 5px;
}
.smart-form .checkbox input + i:after {
  color: #8B9635;
}
.smart-form .checkbox i {
  border-color: #BDBDBD;
  border: 2px solid #666666;
  border-radius: 5px;
}
.smart-form .checkbox:hover i {
  border-color: #666666;
}
@charset "UTF-8";
#ribbon ol.breadcrumb {
  font-size: 13px;
  font-weight: 600;
}
.green-label {
  color: #8B9635 !important;
}
.form-control.ng-invalid.is-invalid {
  border-color: #dc3545 !important;
}
.dt-toolbar {
  display: none !important;
}
.borderHead-align .dt-toolbar {
  background: transparent;
  display: block !important;
  border-bottom: none !important;
  z-index: 999;
  height: 1px;
}
.borderHead-align .dt-toolbar .dataTables_length {
  visibility: hidden;
}
table {
  width: 100%;
  background-color: inherit !important;
  color: #ffffff;
  border-collapse: collapse;
}
.demo {
  position: absolute;
  top: 5px;
  right: 0;
  width: 160px;
  z-index: 902;
  background: #F1DA91;
}
.demo label {
  margin-bottom: 4 !important;
}
.demo label input[type=checkbox].checkbox + span:before {
  border: 1px solid #5E5E5E;
}
.demo label input[type=radio].radiobox + span:before {
  border: 1px solid #5E5E5E;
}
.demo label:hover input[type=checkbox].checkbox + span:before {
  border-color: #E77317;
}
.demo .checkbox {
  padding-left: 22px;
}
.demo .checkbox i {
  border-color: #7A7A7A;
}
.demo .radio {
  padding-left: 22px;
}
.demo #demo-setting + form {
  display: none;
}
.demo span {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  height: 30px;
  width: 30px;
  border-radius: 5px 0 0 5px;
  background: #F1DA91;
  padding: 2px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
}
.demo span i {
  font-size: 17px;
}
.demo span:hover i {
  color: #333 !important;
}
.demo form section {
  margin: 0 !important;
}
.demo .btn {
  font-size: 13px !important;
  line-height: 1.5 !important;
  border-radius: 3px !important;
  padding: 3px 7px !important;
  height: auto !important;
}
.demo.activate {
  right: 0 !important;
  box-shadow: -11px 12px 23px rgba(0, 0, 0, 0.2);
  padding: 5px 10px 10px;
}
.demo.activate #demo-setting + form {
  display: block;
}
.demo.activate span {
  left: -30px;
}
.demo.activate span i:before {
  content: "";
}
.demo-liveupdate-1 {
  font-size: 12px !important;
  position: absolute !important;
  left: 33px;
  top: 15px;
  display: block;
  z-index: 2;
  font-weight: 700;
  padding: 1px 61px 3px 7px;
}
.demo-btns {
  margin: 0;
  padding: 0;
  list-style: none;
}
.demo-btns li {
  display: inline-block;
  margin-bottom: 7px;
}
.demo-icon-font {
  font-size: 14px;
  margin-bottom: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.demo-icon-font:hover {
  background-color: rgba(86, 61, 124, 0.1);
  text-decoration: none;
}
.demo-modal {
  width: auto;
  max-width: 600px;
  z-index: 1;
}
.demo-border {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.demo-vertical-uislider {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.demo-vertical-uislider div {
  height: 200px;
  margin: 0 0 0 10px;
  display: inline-block;
}
.demo-vertical-uislider:first-child {
  margin-left: 15px;
}
.toggle-demo {
  position: absolute;
  top: 10px;
  right: -8px;
  display: block;
  width: 154px;
  height: auto;
  z-index: 99;
  opacity: 0.9;
}
.toggle-demo:hover {
  opacity: 1;
}
.toggle-demo .btn-group {
  width: 100px;
  float: left;
}
.toggle-demo span {
  display: inline-block;
  float: left;
  margin-right: 5px;
  line-height: 21px;
  color: #757575;
}
#header .menu-item {
  margin: 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  cursor: pointer;
}
#header .menu-item_pading {
  padding: 0 10px;
}
#header .menu-left-item {
  padding: 0 10px;
}
#header .company-title {
  font: normal normal bold 12px/19px Open Sans;
}
#header .company-name {
  font: normal normal normal 14px/19px Open Sans;
}
#header #fullscreen .svg-icon {
  background-repeat: no-repeat;
  background-position: center;
  width: 60px !important;
  height: 45px !important;
}
#header .notification-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 60px !important;
  height: 45px !important;
}
#header #activity.activity-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 60px !important;
  height: 45px !important;
}
#header .collapseMenu .collaspe-menu-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 40px !important;
  height: 30px !important;
}
#header .collapseMenu .collaspe-menu-sub-svg {
  background-repeat: no-repeat;
  width: 45px !important;
  height: 45px !important;
}
#header .header-btn:hover {
  border-color: #8B9635 !important;
  background: #8B9635 !important;
}
#header .dropdown-icon-menu ul li .btn:hover {
  border-color: #8B9635 !important;
  background: #8B9635 !important;
}
.knobs-demo div {
  display: inline-block;
  margin-left: 30px;
}
.knobs-demo:first-child {
  margin-left: 0;
}
.widget-box {
  background-color: #ffffff;
  box-shadow: 10px 10px 45px 0 rgba(19, 46, 72, 0.2);
  border-radius: 5px;
  overflow: hidden;
  padding: 20px;
}
.widget-box .title-box .title .ed-icon {
  transform: translateY(-50%) scale(0.8);
  transform-origin: left center;
}
.widget-box .title-box .title .title-lbl {
  font-size: 18px;
  line-height: 18px;
  padding-left: 35px;
}
.widget-box .title-box .title .title-lbl:before {
  left: 25px;
}
.widget-box .title-box .title .title-lbl.no-icon {
  padding-left: 0;
}
.widget-box .title-box .title .title-lbl.no-icon:before {
  display: none;
}
.widget-box .icon-box {
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.widget-box .icon-box .number {
  font-size: 30px;
}
.widget-box .icon-box .ed-icon {
  width: 30px;
  height: 30px;
}
.widget-box h3 {
  font-size: 24px;
  line-height: 24px;
}
.widget-box p {
  font-size: 18px;
  line-height: 20px;
}
.activity.ajax-dropdown:first-child {
  margin: 0;
  padding: 0;
}
.activity .ajax-notifications {
  border-bottom: none;
  border-top: none;
}
.fixed-navigation .nav-demo-btn {
  display: none !important;
}
.minified .nav-demo-btn,
.minified .ac_ConnectionSection {
  display: none !important;
}
.smart-rtl .demo .margin-right-5 {
  margin-right: 0 !important;
}
.listFinal {
  font-size: 16px;
  padding: 5px;
}
.listFinal::before {
  content: ">";
  font-size: 20px;
  padding: 5px;
}
.minified #clearCache_text,
.minified .module-name {
  display: none;
}
.minified #help_text {
  display: none;
}
.minified .userlabel {
  display: none !important;
}
.minified .body-image {
  width: 40px !important;
  height: 40px !important;
  margin: -15px 0px 10px -45px;
}
.minified .footer-menu {
  padding: 12px 0px !important;
}
.minified .tutorial-popup {
  width: 43px !important;
  padding: 3px 12px;
}
.minified .module {
  padding: 5px 5px 5px 10px !important;
}
.minified .module .module-img {
  width: 26px !important;
}
.minified .chatIcon {
  margin-left: 8px;
}
.minified #clearCache .iom_btn {
  padding: 3px 13px !important;
}
.minified .ebill-icon {
  width: 26px !important;
}
.minified .hrColor {
  display: none;
}
.minified .ebill-nav {
  padding: 5px 8px !important;
}
.minified #left-panel {
  padding-top: 0px !important;
}
.header-dropdown-list li .dropdown-toggle {
  margin-top: 6px;
}
.dash-icon {
  background-image: url(/assets/img/application/dashboard.svg);
}
.purchase-icon {
  background-image: url(/assets/img/application/purchase.svg);
}
.inv-icon {
  background-image: url(/assets/img/application/invoice.svg);
}
.file-icon {
  background-image: url(/assets/img/application/filemanager.svg);
}
.vmi-icon {
  background-image: url(/assets/img/application/vmi.svg);
}
.contract-icon {
  background-image: url(/assets/img/application/contract.svg);
}
.edi-icon {
  background-image: url(/assets/img/application/edi.svg);
}
.header-dropdown-list .dropdown-menu {
  padding: 4px;
  overflow: auto;
  max-height: 200px;
  border-radius: 10px;
}
.header-dropdown-list .dropdown-menu li a {
  border-radius: 0 !important;
  font-size: 14px;
}
.header-dropdown-list .dropdown-menu::-webkit-scrollbar {
  width: 0px;
}
.nav-demo-btn {
  display: none;
}
body:not(.menu-on-top) .nav-demo-btn {
  display: block;
  padding: 6px 5px;
  margin: 5px 10px;
  width: auto;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  font-size: 12px;
  white-space: normal;
}
.ajax-dropdown-app > :first-child {
  margin: 0 0 3px;
  padding: 0 0 9px;
}
.hidden-menu .page-footer {
  padding-left: 20px;
}
.main-nav {
  text-align: right;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #E2E2E2;
  opacity: 1;
  padding-top: 11px;
}
.setCircleactive {
  color: #00eba9;
  font-size: 10px !important;
}
.setCirclehold {
  color: #3400eb;
  font-size: 10px !important;
}
.setCirclerej {
  color: #eb0042;
  font-size: 10px !important;
}
.main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 0;
  padding-right: 10px;
}
#content {
  background: #1E1E1E !important;
}
.footerTextColor {
  color: #8B91A0 !important;
}
.theme-header-color {
  color: #E2E2E2 !important;
}
.header-menu {
  font: normal normal normal 14px/19px Open Sans;
}
.notification-body > li {
  border: 1px solid #e3e3e3 !important;
}
.flag.flag-en {
  background-position: -144px -154px;
}
#left-panel {
  position: absolute;
  top: 51px;
  z-index: 904;
  padding-top: 10px;
}
#left-panel nav ul li.active > a:before {
  right: -19px;
  content: none !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.notification-body .subject {
  color: #7A7A7A !important;
}
.iombackground {
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh !important;
  background: #C04848;
  /* fallback for old browsers */
  background: linear-gradient(rgba(102, 102, 102, 0.9), rgba(102, 102, 102, 0.9)), url(/assets/img/register.png);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(rgba(102, 102, 102, 0.9), rgba(102, 102, 102, 0.9)), url(/assets/img/register.png);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: cover;
  background-repeat: no-repeat;
}
.scroll-content::-webkit-scrollbar {
  display: none;
}
.scroll-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.mobile-view-activated::-webkit-scrollbar {
  display: none;
}
.mobile-view-activated {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.MessageBoxButtonSection button {
  border-radius: 20px;
  background: transparent;
  border: 2px solid #8B9635 !important;
  font-size: 13px;
  padding: 3px 15px;
  font-weight: 400 !important;
}
.MessageBoxButtonSection button:hover {
  border-radius: 20px;
  background: #8B9635 !important;
  border: 2px solid #8B9635 !important;
  font-size: 13px;
  padding: 3px 15px;
  color: #000 !important;
  font-weight: 400 !important;
}
.minified nav > ul > li > a > .menu-item-parent {
  top: -1px;
  height: 41px;
}
.ui-datepicker {
  border-radius: 5px !important;
}
.ui-datepicker select.ui-datepicker-month {
  margin: 0 2px;
  width: 47%;
}
.ui-datepicker select.ui-datepicker-year {
  margin: 0 2px;
  width: 41%;
}
.ui-datepicker td .ui-state-default {
  color: #ffffff;
}
.ui-datepicker .ui-datepicker-next span {
  background: none !important;
}
.ui-datepicker .ui-datepicker-prev span {
  background: none !important;
}
.ui-datepicker-prev span {
  background-image: none !important;
}
.ui-datepicker-next span {
  background-image: none !important;
}
.ui-datepicker-prev:before {
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  z-index: 9;
  padding-top: 4px;
  content: "";
}
.ui-datepicker-next:before {
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  z-index: 9;
  padding-top: 4px;
  content: "";
}
.ui-datepicker {
  width: 19em;
  padding: 0.2em 0.2em 0;
  display: none;
}
nav ul ul {
  padding: 0px;
}
nav ul ul li {
  padding: 6px;
}
nav ul ul li > a:hover {
  background: #8B9635 !important;
}
nav ul ul li > a:hover {
  background: #666 !important;
}
nav ul b .fa-plus-square-o:before {
  content: "" !important;
}
nav ul b .fa-minus-square-o:before {
  content: "" !important;
}
.table thead:first-child tr:first-child th {
  border-bottom: none !important;
}
.btn-primary {
  background: transparent !important;
  border: 2px solid #8B9635;
  padding: 3px 15px;
  border-radius: 15px;
  font-size: 13px;
}
.btn-primary:hover {
  background: #8B9635 !important;
  color: #171717 !important;
  border: 2px solid #8B9635;
}
.btn-primary:focus {
  box-shadow: none;
}
.custom-filter {
  padding: 3px 23px !important;
}
.commonHead {
  font-size: 14px;
  border: unset !important;
}
.commonHead h2 {
  margin-left: 3px !important;
  font-size: 16px;
}
.form-control {
  height: 40px !important;
  border-radius: 6px !important;
  font-size: 14px;
}
.iom-button {
  border-radius: 20px;
  background: transparent;
  border: 2px solid #8B9635;
  font-size: 13px;
  padding: 7px 28px;
}
.iom-button:hover {
  background: #8B9635 !important;
  color: #000 !important;
}
.btn-primary:hover {
  background: #8B9635 !important;
  color: #000 !important;
}
@media (max-width: 979px) {
  .page-footer {
    padding: 5px 14px 0;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  .flag + span {
    max-width: 100% !important;
  }
}
@media (max-width: 576px) {
  .joyride-step__container {
    width: auto;
  }

  textarea.in {
    width: auto;
  }
}
.minified .profile-image-box {
  display: none;
}
.minified ul.navList {
  margin-top: -25px !important;
}
.minified .description,
.minified .ac_ConnectionSection,
.minified .company-label {
  display: none;
}
.minified .helpSection {
  height: 40px !important;
}
.minified .chatIcon img {
  margin-left: -8px;
}
.tranhoverTable tbody tr td.text-left.green-text {
  color: #8B9635 !important;
}
.tranhoverTable tbody tr td.text-left.green-text a {
  color: #8B9635 !important;
}
.tranhoverTable tbody tr.selected td.text-left.green-text {
  color: #171717 !important;
}
.tranhoverTable tbody tr.selected td.text-left.green-text a {
  color: #171717 !important;
}
table.dataTable {
  clear: both;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
}
table.positionTable thead tr th:first-child {
  border-radius: 5px 0px 0px 0px;
}
table.positionTable thead tr th:last-child {
  border-radius: 0px 5px 0px 0px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 5px !important;
  border-top-left-radius: 5px !important;
}
.dt-toolbar-footer {
  padding: 5px 0px;
}
.slider-container {
  margin-left: 25px;
}
.exportBtn-help h1,
.exportBtn-help-table h1 {
  font-size: 18px;
  font-weight: 700;
  margin: 15px 25px;
  overflow: hidden;
}
.searchTableContacts.exportBtn-help:before {
  content: "";
  position: absolute;
  right: -10px;
  top: 48px;
  border-style: solid;
  border-width: 14px 14px 14px 0;
  border-top-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  transform: rotateY(180deg);
}
.joyride-step__holder {
  border-radius: 15px;
  z-index: 2000;
}
.joyride-step__container {
  border-radius: 15px;
  width: 438px !important;
  max-height: 300px !important;
}
.joyride-step__container .joyride-step__body {
  overflow-y: auto;
}
.joyride-step__container .joyride-step__body::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
.joyride-step__container .joyride-step__body::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
}
.joyride-step__container .joyride-step__body::-webkit-scrollbar-thumb {
  background-color: #CCC;
  border-radius: 5px;
}
.joyride-button {
  border-radius: 15px;
  background: transparent !important;
  border: 2px solid #8B9635 !important;
  padding: 3px 15px !important;
  border-radius: 15px !important;
  font-size: 13px !important;
}
.joyride-step__close {
  text-align: right;
  letter-spacing: 0px;
  color: #8B9635 !important;
  opacity: 1;
  font-size: 18px;
  font-weight: bold;
}
.joyride-step__close svg {
  display: none;
}
.joyride-step__close:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  left: 5px;
  position: absolute;
  top: -10px;
  font-size: 18px;
  font-weight: 900;
  text-align: right;
}
.joyride-step__footer {
  justify-content: end !important;
  flex-direction: row-reverse !important;
  padding-top: 10px !important;
}
textarea.in {
  display: none;
  width: 400px !important;
  height: 180px !important;
  font-size: 13px;
  color: #ffffff;
  background-clip: padding-box;
  background-color: unset !important;
  border: 1px solid #333333 !important;
  border-radius: 5px !important;
}
textarea.in::-webkit-scrollbar {
  width: 0px;
}
.tour-title-input {
  border-radius: 5px !important;
  height: 35px;
  width: 100%;
  color: #ffffff;
  background-clip: padding-box;
  background-color: unset !important;
  border: 1px solid #333333 !important;
  font-size: 13px;
  margin-top: 15px;
}
.joyride-step__counter-container {
  margin-right: 10px !important;
}
.backdrop-container {
  z-index: 1100 !important;
}
.joyride-step__holder {
  z-index: 1100 !important;
}
.btn-save {
  display: none;
}
.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
}
.btn-primary:not(:disabled):not(.disabled):active {
  border-color: #8B9635 !important;
}
.idTooltip {
  text-align: center;
  z-index: 99999;
  position: absolute;
  width: 150px;
  height: 30px;
  border-radius: 5px;
  font: normal normal normal 13px/18px Open Sans;
}
.idTooltip .insideDivUl-nohover {
  z-index: 99999;
  list-style: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
}
table.dataTable tbody tr:hover {
  cursor: pointer;
}
/*------------------------- Drop-down Menu -------------------------*/
.dropdown-list {
  margin-top: 4px;
  min-width: 270px;
  font-family: Open Sans, Arial, Helvetica, Sans-Serif;
  position: absolute;
  z-index: 1000;
}
.dropdown-list .dropdown-list-items-contents {
  display: block;
  max-height: calc(100vh - 120px);
  overflow: overlay;
  max-width: 320px;
  border-radius: 10px;
}
.dropdown-list .dropdown-list-row {
  padding: 15px 0px;
  display: flex;
  flex-wrap: wrap;
}
.dropdown-list .dropdown-list-row .list-group-item {
  background-color: transparent !important;
  border: none !important;
  display: flex !important;
  flex-direction: row;
}
.dropdown-list .dropdown-list-row .list-group-item div {
  padding: 0px 2px;
  display: flex;
  width: 25px !important;
}
.dropdown-list .dropdown-list-row .list-group-item div i {
  margin: auto;
}
.dropdown-list .dropdown-list-box {
  width: 33.3333%;
  text-align: center;
  padding: 30px 20px 0;
  font-weight: 500;
}
.dropdown-list .border-bootm-green {
  border-bottom: 1px solid #8B9635;
}
.billerFormSection .input-group-append {
  position: absolute !important;
  right: 0 !important;
  top: 6px !important;
}
.billerFormSection .input-group-append .input-group-text {
  border: unset;
  background-color: unset;
  font-size: 14px;
}
.billerFormSection .companySelect {
  height: 50px;
}
.billerFormSection .companySelect .ng-select-container {
  height: 40px;
}
.billerFormSection .companySelect .ng-dropdown-panel.ng-select-bottom {
  margin-top: -10px !important;
  overflow: auto;
}
.iom_btn,
.ebill_btn {
  border-radius: 15px;
  background: transparent;
  border: 2px solid #8B9635;
  color: #ffffff;
  font-size: 13px;
  padding: 3px 15px;
  line-height: 20px;
  font-weight: 400;
}
.iom_btn:hover,
.ebill_btn:hover {
  border-color: #8B9635 !important;
  background: #8B9635 !important;
  color: #171717 !important;
}
.companySelect .ng-select-container {
  background: #202124 !important;
  border: 1px solid #6666;
}
.companySelect .ng-select-container .ng-placeholder {
  font-size: 13px;
}
.companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #666 !important;
  border: 1px solid #333333 !important;
  font-size: 13px !important;
}
.companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #202124 !important;
  color: #ffffff;
  font-size: 13px !important;
}
.companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: #202124 !important;
}
.companySelect .requiredSector {
  color: #dc3545;
}
.companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #8B9635 !important;
  font-size: 13px !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent !important;
  border: 1px solid #666;
  border-bottom: unset;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  background-color: transparent !important;
  border: unset;
}
.ebillForm formly-field-select select option {
  background-color: #202124 !important;
}
.ebillForm .commmonWrap formly-group {
  width: 100% !important;
}
select option {
  background-color: #202124 !important;
}
.commmonWrap formly-group {
  width: 100% !important;
}
.requiredSection .ng-select-container {
  border-color: #dc3545 !important;
}
.requiredSector {
  color: #dc3545 !important;
}
.billerFormSection formly-wrapper-form-field div {
  font-size: 13px !important;
}
.biller_tabSection .nav-item {
  font-size: 14px !important;
}
.chatIcon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.refreshStatus {
  margin-right: 14px;
  float: right;
  margin-top: -5px;
}
.refreshStatus-popup {
  margin-right: 14px;
  float: right;
  margin-top: -5px;
}
/*------------------------- Drop-down Menu -------------------------*/
.wizard .plusBtn {
  position: absolute;
  right: 20px;
  cursor: pointer;
  top: 13px;
}
.wizardCircleWidth {
  width: 20%;
  flex: 1 0 20% !important;
}
.wizardTextWidth {
  width: 75%;
  flex: 1 0 75% !important;
  padding: 0px 10px;
  display: flex;
  align-items: flex-start !important;
}
.wizardDiv {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  font-size: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
b.badges {
  position: absolute;
  cursor: pointer;
  font-size: 10px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  padding: 2px 4px 3px;
  text-align: center;
  line-height: normal;
  content: "";
  top: -1px;
  right: -1px;
  transform: translate(25%, -25%);
  width: 25px;
  height: 25px;
  color: #0C1214;
  background-color: #8B9635;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
}
.accordianXpand {
  transition: all 0.2s ease-in-out 0s !important;
}
.wizard#accordionExpandWizard {
  border-radius: 10px;
  color: #333333;
  padding: 20px 20px 0px;
}
.wizardCircleWidth {
  width: 20%;
  flex: 1 0 20% !important;
}
.line {
  width: 5%;
  z-index: 1;
  position: relative;
}
.common_wizardItemContainer .common_wizardItems {
  width: 35% !important;
  cursor: pointer;
}
.wizard-items {
  width: 30%;
  border-radius: 10px;
  z-index: 100;
  position: relative;
  min-height: 90px;
  display: flex;
}
.green-border {
  border: 1px solid #8B9635 !important;
}
#wizard .wizard-container {
  display: flex;
  flex-direction: row;
}
#wizard {
  border-radius: 15px;
  padding: 10px 20px;
}
.wizardTextWidth {
  width: 95%;
  flex: 1 0 75% !important;
  padding: 0px 10px;
  display: flex;
  align-items: flex-start !important;
}
.form-group .form-control {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 0;
  font-size: 12px;
  height: 40px;
}
.wizard-billeritem {
  width: 30%;
  border-radius: 10px;
  z-index: 100;
  position: relative;
  min-height: 90px;
  display: flex;
}
.line-1 {
  height: 1px;
  width: 100%;
  z-index: 200;
  position: relative;
  top: 50% !important;
}
.billerFormSection formly-wrapper-form-field div {
  font-size: 13px !important;
}
.biller_tabSection .nav-item {
  font-size: 14px !important;
}
.form-group .form-control {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 0;
  font-size: 12px;
  height: 40px;
}
.form-group textarea.form-control {
  height: unset !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.communicationPanel {
  display: block !important;
}
.billerFormSection formly-wrapper-form-field div {
  font-size: 14px !important;
}
.includePdf formly-field-radio div .custom-radio {
  padding-left: 2.5rem;
}
.includePdf formly-field-radio div {
  display: flex;
}
.ebillForm formly-field-select select option {
  background-color: #202124 !important;
}
.ebillForm .commmonWrap formly-group {
  width: 100% !important;
}
.commmonWrap formly-group {
  width: 100% !important;
}
table.positionTable thead tr th:first-child {
  border-radius: 5px 0px 0px 0px;
}
table.dataTable thead > tr > th.sorting_disabled {
  padding-right: 9px !important;
}
.bg-color-green {
  background-color: #8B9635 !important;
}
.dataTable tbody tr td {
  font-size: 13px;
}
.modal-content {
  border-radius: 10px;
}
.nav_btn {
  border-radius: 15px;
  background: transparent;
  border: 2px solid #8B9635;
  color: #ffffff;
  font-size: 13px;
  padding: 3px 15px;
  line-height: 20px;
  font-weight: 400;
}
.nav_btn:hover {
  border-color: #8B9635 !important;
  background: #8B9635 !important;
  color: #171717 !important;
}
ul.invoice-tab > li.active > a {
  border: 1px solid #8B9635 !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  box-shadow: 0 -2px 0 #8B9635 !important;
}
.table tbody tr td {
  padding: 7px;
  line-height: 1.42857143;
  vertical-align: top;
}
.invoice-tab-header .nav-tabs > li {
  text-align: center;
}
.invoice-tab-header .nav-tabs > li > a {
  margin: auto;
}
.tooltipA {
  position: relative;
  display: inline-block;
}
/* Tooltip text */
.tooltipA .tooltiptext {
  visibility: hidden;
  min-width: 180px;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 100;
  top: -5px;
  right: 40%;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltipA:hover .tooltiptext {
  visibility: visible;
}
.error-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
}
.error-div div.error-div-div {
  width: 300px;
  width: 300px;
  height: 200px;
  border-radius: 8px;
  padding: 5px;
}
tr.selected span.green-label {
  color: #0C1214 !important;
}
.scroll-tbs {
  height: 170px !important;
  overflow-y: auto !important;
  margin-top: 10px;
  margin-left: -3px;
}
.scroll-tbs::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
.scroll-tbs::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
}
.scroll-tbs::-webkit-scrollbar-thumb {
  background-color: #CCC;
  border-radius: 5px;
}
.scroll-tbs table.dataTable tbody tr.selected {
  background-color: #8B9635 !important;
  border-color: #cccccc7a;
  color: #171717 !important;
}
.scroll-tbs table.dataTable tbody tr.selected:hover {
  background-color: #8B9635 !important;
  border-color: #cccccc7a;
}
.dividerOr .alignOr {
  display: flex !important;
  justify-content: center !important;
  margin: 0px !important;
  padding-top: 10px !important;
  font-size: 14px;
}
.searchRecipientForm formly-field label {
  font-size: 13px;
}
.searchRecipientForm formly-field formly-field-input input {
  border-radius: 10px !important;
}
.searchRecipientForm formly-field formly-validation-message {
  font-size: 12px;
}
.filter-top {
  font-size: 16px !important;
  letter-spacing: 0;
  font-weight: 400;
  font-family: Open Sans, Arial, Helvetica, Sans-Serif;
}
.main-title {
  font-size: 16px !important;
  font-family: Open Sans, Arial, Helvetica, sans-serif;
}
.commonCl {
  flex: 1 0 20%;
  margin: 0px 6px;
}
.app-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
.app-tut {
  width: 220px;
  height: 220px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  cursor: pointer;
}
.app-tut .app-inner-tut {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app-tut:first-child {
  margin-left: 0px !important;
}
.app-tut:last-child {
  margin-right: 0px !important;
}
.app-img {
  width: 100px;
  height: 100px;
}
.app-img img {
  width: 100px;
  height: 100px;
}
.app-title {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}
textarea.modal-in {
  min-width: 400px;
  height: 300px;
  font-size: 13px;
  background-clip: padding-box;
  border: 1px solid #666666 !important;
  border-radius: 10px !important;
}
textarea.form-control {
  height: unset !important;
}
.closeModal a {
  cursor: pointer;
}
.inner-divs {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.snd-rcv {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}
.snd-rcv:first-child {
  margin-left: 0px !important;
}
.snd-rcv:last-child {
  margin-right: 0px !important;
}
.ordr-invc {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.ordr-invc:first-child {
  margin-left: 0px !important;
}
.ordr-invc:last-child {
  margin-right: 0px !important;
}
.show-contacts-div {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  cursor: pointer;
}
.code-div {
  height: auto;
  margin: 20px 0 0 0;
}
.invite-form-div {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 20px;
  cursor: pointer;
  white-space: nowrap;
}
.snd-rcv-div {
  border-radius: 10px;
  width: 250px;
  height: 220px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.snd-rcv-div .snd-rcv-img {
  width: 100px;
  height: 100px;
}
.snd-rcv-div .snd-rcv-img img {
  width: 100px;
  height: 100px;
}
.snd-rcv-div .snd-rcv-title {
  margin-top: 10px;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.ordr-invc-div.nohover {
  cursor: no-drop;
}
.ordr-invc-div {
  border-radius: 10px;
  width: 210px;
  height: 220px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #8B9635;
}
.ordr-invc-div:not(.nohover) {
  cursor: pointer;
}
.ordr-invc-div .ordr-invc-img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ordr-invc-div .ordr-invc-img img {
  width: 100px;
  height: 100px;
}
.ordr-invc-div .ordr-invc-title {
  display: flex;
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  word-wrap: break-word;
  overflow: auto;
  white-space: pre-wrap;
  align-items: center;
  justify-content: center;
}
.nxt-btn,
.prev-btn {
  font-size: 16px !important;
}
.direction-title {
  text-transform: uppercase;
}
.create-contact .formly-group {
  margin-bottom: 13px !important;
}
@media screen and (max-width: 767px) {
  .table-responsive {
    border: none !important;
  }
}
.tableEmail a {
  font-weight: 400;
}
.dt-toolbar .dataTables_filter {
  display: none !important;
}
.dt-toolbar .dataTables_length {
  display: block !important;
}
#overlayModal {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 1060;
}
.companyTooltip {
  text-align: center;
  z-index: 1000;
  position: fixed;
  border-radius: 5px;
  font: normal normal normal 13px/18px Open Sans;
}
.companyTooltip .insideDivUl-nohover {
  list-style: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
  word-break: break-all;
  height: auto;
}
/*
---------------------------------------------------------------------------------------
  Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-1 {
  /*------------------------- Drop-down Menu in header -------------------------*/
  /*------------------------- Drop-down Menu in header -------------------------*/
  /* CSS for edi dashboard */
  /* CSS for edi dashboard */
}
.smart-style-1 .searchTableContacts.exportBtn-help:before,
.smart-style-1 .searchTable.exportBtn-help:before {
  border-right-color: #202124;
}
.smart-style-1 .exportBtn-help h1,
.smart-style-1 .exportBtn-help-table h1 {
  color: #ffffff !important;
}
.smart-style-1 .searchTableContacts.exportBtn-help,
.smart-style-1 .searchTable.exportBtn-help {
  background-color: #202124 !important;
}
.smart-style-1 body {
  background-color: #1E1E1E !important;
}
.smart-style-1 #ribbon .breadcrumb li:last-child {
  color: #000 !important;
}
.smart-style-1 .breadcrumb > li + li:before {
  color: #000;
}
.smart-style-1 #left-panel nav ul li {
  border-bottom: 1px solid #8B9635;
}
.smart-style-1 #left-panel nav ul li:hover {
  background: #8B9635 !important;
  color: #000 !important;
  border: 1px solid #3B3B3B33;
  border-bottom: 1px solid #3B3B3B33 !important;
}
.smart-style-1 #left-panel nav ul li.active {
  background-color: #8B9635 !important;
}
.smart-style-1 #left-panel nav ul li.active a {
  color: #202427 !important;
}
.smart-style-1 #left-panel nav ul li.active ul li {
  color: #202427 !important;
}
.smart-style-1 #left-panel nav ul li.active ul li:hover a {
  color: #202427 !important;
  background: #8B9635 !important;
}
.smart-style-1 #left-panel nav ul li.active ul li a {
  color: #ffffff !important;
}
.smart-style-1 #left-panel nav ul li.active ul li.active a {
  color: #202427 !important;
}
.smart-style-1 #left-panel nav ul li.open li:hover a {
  color: #202427 !important;
}
.smart-style-1 #ribbon .breadcrumb li {
  color: #8B91A0;
}
.smart-style-1 #ribbon .breadcrumb li:last-child {
  color: #ffffff !important;
}
.smart-style-1 .theme-header-text-color {
  color: #ffffff !important;
}
.smart-style-1 .theme-subtext-color {
  color: #959595 !important;
}
.smart-style-1 #content {
  background: #202124 !important;
}
.smart-style-1 .theme-icon {
  color: #ffffff !important;
}
.smart-style-1 .ajax-dropdown {
  border: 1px solid #313840 !important;
}
.smart-style-1 .notification-body li {
  border: 1px solid #252b31 !important;
}
.smart-style-1 .dropdown-menu {
  background-color: #0C1214;
  border: 1px solid #3B424C;
}
.smart-style-1 .dropdown-menu li a {
  color: #ffffff;
}
.smart-style-1 .header-dropdown-list .dropdown-menu li a:hover {
  background-color: #1E1E1E !important;
}
.smart-style-1 .header-dropdown-list .dropdown-menu .active a {
  background-color: #1E1E1E !important;
}
.smart-style-1 #fullscreen .svg-icon {
  background-image: url('Path 222.737b381f1c9fbc85a137.svg');
}
.smart-style-1 #header .notification-svg {
  background-image: url('39-Notification.4feddd112452285fe534.svg');
}
.smart-style-1 #header #activity.activity-svg {
  background-image: url('Page-1.9f2dc138f1ca7885f995.svg');
}
.smart-style-1 #header .collapseMenu .collaspe-menu-svg {
  background-image: url('Path 224.c485c714dd85f9b4f52d.svg');
}
.smart-style-1 #header .collapseMenu .collaspe-menu-sub-svg {
  background-image: url('Group224.649ca2f656deeb39e0a7.svg');
}
.smart-style-1 #header .collapseMenu:hover {
  background-color: #0D0F12;
}
.smart-style-1 #header .header-btn {
  background-color: #1E1E1E;
}
.smart-style-1 #header .header-btn-list a {
  background-color: #1E1E1E !important;
}
.smart-style-1 #header .menu-item:hover {
  background-color: #666666;
}
.smart-style-1 #header .menu-item.no-hover:hover {
  background-color: #1E1E1E;
}
.smart-style-1 #header .menu-left-item {
  border-left: unset !important;
}
.smart-style-1 .jarviswidget-color-darken header {
  background: #171819 !important;
  border: none;
}
.smart-style-1 .border {
  border: 1px solid #333333 !important;
  border-radius: 10px;
}
.smart-style-1 .jarviswidget > div {
  border: none;
}
.smart-style-1 nav ul li a {
  color: #ffffff !important;
  font-size: 16px !important;
}
.smart-style-1 nav ul li a:focus, .smart-style-1 nav ul li a:hover {
  color: #202427 !important;
  background: #8B9635 !important;
  color: #202427 !important;
}
.smart-style-1 nav ul li.open a {
  color: #ffffff !important;
  font-weight: 400;
}
.smart-style-1 nav ul li.open:focus, .smart-style-1 nav ul li.open:hover {
  color: #ffffff !important;
  background: #8B9635 !important;
}
.smart-style-1 nav ul li.open a b {
  color: #ffffff !important;
}
.smart-style-1 nav ul li.open.active a b {
  color: #202427 !important;
}
.smart-style-1 nav ul li.open:hover > a {
  color: #202427 !important;
}
.smart-style-1 nav ul li.open:hover > a b {
  color: #202427 !important;
}
.smart-style-1 nav ul ul li a {
  color: #ffffff;
  font-size: 14px !important;
  text-shadow: none !important;
}
.smart-style-1 nav ul ul li a:focus, .smart-style-1 nav ul ul li a:hover {
  color: #202427 !important;
  background: #8B9635 !important;
}
.smart-style-1 .model-7 .checkbox label:after {
  background: #000 !important;
}
.smart-style-1 .editButton {
  font-size: 37px;
  text-align: center;
  font: normal normal normal 30px/41px Open Sans;
  letter-spacing: -0.3px;
  background-color: #202124;
  border: 2px solid #8B9635;
  color: #202528;
  border-radius: 19px;
  font-size: 13px;
  top: 55px;
  position: relative;
  float: right;
  width: 155px;
  height: 34px;
  right: 217px;
}
.smart-style-1 .editButton:hover {
  background: #8B9635 !important;
  color: #0C1214 !important;
}
.smart-style-1 .modalBtn {
  max-width: 150px;
  width: 110px;
  margin-top: 11px;
  color: #ffffff !important;
  border: 2px solid #8B9635;
  padding: 7px 0px;
}
.smart-style-1 .modalBtn:hover {
  background: #8B9635 !important;
  color: #0C1214 !important;
}
.smart-style-1 .profileEditTextBox {
  background: #202124 0% 0% no-repeat padding-box !important;
}
.smart-style-1 .changePasswordBtn:hover,
.smart-style-1 .editProfileBtn:hover {
  border-color: #8B9635 !important;
  background: #8B9635 !important;
  color: #000 !important;
}
.smart-style-1 .ui-datepicker {
  background: #303030 !important;
}
.smart-style-1 .ui-datepicker .ui-datepicker-header {
  background-color: rgba(255, 255, 225, 0.15);
}
.smart-style-1 .ui-datepicker select.ui-datepicker-month {
  border-color: #303030;
  background: #303030;
}
.smart-style-1 .ui-datepicker select.ui-datepicker-year {
  border-color: #303030;
  background: #303030;
}
.smart-style-1 .ui-datepicker th {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker td .ui-state-default {
  color: #ffffff;
}
.smart-style-1 .ui-datepicker td .ui-state-active {
  background: #707070;
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-header a {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-month {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-year {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-prev:before {
  background: #303030;
}
.smart-style-1 .ui-datepicker-next:before {
  background: #303030;
}
.smart-style-1 .commonHead {
  background-color: #202124 !important;
  color: #EEE;
}
.smart-style-1 .jarviswidget > div {
  background-color: #202124 !important;
  border: none;
}
.smart-style-1 .headData {
  background: #1e1e1e 0 0 no-repeat padding-box;
  border: 1px solid #333333;
  margin-top: 10px;
  color: #ffffff;
}
.smart-style-1 .dt-toolbar-footer {
  background: none !important;
  font-size: none !important;
  overflow: hidden;
  padding: none !important;
  border-top: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important;
  width: 100%;
}
.smart-style-1 table {
  margin-top: 0px !important;
  border: 1px solid #333333 !important;
  border-radius: 5px;
}
.smart-style-1 table tbody tr {
  color: #ffffff;
}
.smart-style-1 table tbody tr td {
  line-height: 1.42857143;
  vertical-align: middle;
  border-color: #333333;
}
.smart-style-1 .pagination > .active > a {
  background-color: #8B9635 !important;
  border-color: #8B9635 !important;
  color: #171717 !important;
  border-color: #333333;
}
.smart-style-1 .pagination > li > a {
  background-color: transparent !important;
  color: #ffffff;
  border-color: #333333;
}
.smart-style-1 .pagination > li:first-child > a,
.smart-style-1 .pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-color: transparent !important;
  color: #ffffff;
  border-color: #333333;
}
.smart-style-1 .pagination > li:last-child > a,
.smart-style-1 .pagination > li:last-child > span {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: transparent !important;
  border-color: #333333;
}
.smart-style-1 .table tbody tr th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #333333;
  border-color: #333333;
}
.smart-style-1 .table tfoot tr td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #333333;
  border-color: #333333;
}
.smart-style-1 .table tfoot tr th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #3B424C;
  border-color: #3B424C;
}
.smart-style-1 .table thead tr td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #333333;
  border-color: #333333;
}
.smart-style-1 .table thead tr th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #333333;
  border-color: #333333;
}
.smart-style-1 .btn-primary {
  color: #ffffff;
}
.smart-style-1 .form-control {
  background: transparent !important;
  border: 1px solid #333333;
  color: #ffffff;
}
.smart-style-1 .form-group label {
  color: #ffffff;
}
.smart-style-1 .iom-button {
  color: #ffffff;
}
.smart-style-1 .input-group-text {
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #171819;
  background-color: #171819;
}
.smart-style-1 .ui-datepicker {
  background: #202124 !important;
  border-color: #333333 !important;
}
.smart-style-1 .ui-datepicker .ui-datepicker-header {
  background-color: #202124;
}
.smart-style-1 .ui-datepicker select.ui-datepicker-month {
  border-color: #171717;
  background: #202124 !important;
}
.smart-style-1 .ui-datepicker select.ui-datepicker-year {
  border-color: #171717;
  background: #202124 !important;
}
.smart-style-1 .ui-datepicker th {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker td .ui-state-active {
  background: #707070;
}
.smart-style-1 .ui-datepicker-header a {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-month {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-year {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-prev:before {
  background: #202124 !important;
}
.smart-style-1 .ui-datepicker-next:before {
  background: #202124 !important;
}
.smart-style-1 .table-hover tbody tr:hover {
  color: #ffffff !important;
  background-color: #7c7b7b;
}
.smart-style-1 .smallLang {
  display: block !important;
}
.smart-style-1 .smallLang .dropdown-toggle::after {
  color: #000 !important;
}
.smart-style-1 .smallLang .header-dropdown-list .dropdown-toggle .language-text {
  color: #212529 !important;
}
.smart-style-1 .smallLang .header-dropdown-list .dropdown-menu {
  background-color: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
}
.smart-style-1 .smallLang .header-dropdown-list .dropdown-menu li a:hover {
  background-color: #EDEDED !important;
}
.smart-style-1 .smallLang .header-dropdown-list .dropdown-menu .active a {
  background-color: #EDEDED !important;
}
.smart-style-1 .smallLang .header-dropdown-list .dropdown-menu .dropdown-item {
  color: #212529 !important;
}
.smart-style-1 .modal-body {
  background-color: #171717 !important;
}
.smart-style-1 .modal-content {
  background-color: #171717 !important;
  border: 1px solid #333;
  border-radius: 10px;
  color: #ffffff;
  width: 800px;
}
.smart-style-1 .modal-footer {
  padding: 20px 0px 0px 0px;
  border-top: none;
}
.smart-style-1 .btnModalSave {
  float: right;
  background-color: #202124;
  border: none;
  border-radius: 15px;
  border: 2px solid #8B9635;
}
.smart-style-1 .btnModalClose {
  float: left;
  background-color: #202124;
  border: none;
  border-radius: 15px;
  border: 2px solid #8B9635 !important;
}
.smart-style-1 .successDiv {
  background-color: #171819;
}
.smart-style-1 .successDiv-popup {
  background-color: #171819;
}
.smart-style-1 .successDiv-popup-accept {
  background-color: #171819;
}
.smart-style-1 .theme-content-color {
  color: #ffffff;
}
.smart-style-1 .MessageBoxButtonSection button {
  color: #ffffff;
}
.smart-style-1 .cancellabel {
  border: 1px solid #333333;
  background-color: #202124;
}
.smart-style-1 .joyride-step__holder {
  background-color: #171717 !important;
  box-shadow: 0 0 0px 1px #171717 !important;
}
.smart-style-1 .joyride-step__holder .joyride-step__container {
  background-color: #171717 !important;
  border: 1px solid #333333 !important;
  box-shadow: 0 0 0px 1px #171717 !important;
}
.smart-style-1 .joyride-step__holder .joyride-arrow__top {
  border-bottom: 11px solid #333333 !important;
}
.smart-style-1 .joyride-step__holder .joyride-arrow__right {
  border-left: 11px solid #333333 !important;
}
.smart-style-1 .joyride-step__holder .joyride-arrow__left {
  border-right: 11px solid #333333 !important;
}
.smart-style-1 .joyride-step__holder .joyride-arrow__bottom {
  border-top: 11px solid #333333 !important;
}
.smart-style-1 .joyride-step__holder .joyride-button {
  color: #ffffff !important;
}
.smart-style-1 .joyride-step__holder .joyride-button:hover {
  background-color: #8B9635 !important;
  color: #0C1214 !important;
}
.smart-style-1 .joyride-step__holder .joyride-step__title,
.smart-style-1 .joyride-step__holder .joyride-step__body {
  color: #ffffff !important;
}
.smart-style-1 .joyride-step__holder textarea.in {
  color: #ffffff;
  background-color: #0C1214;
  border: 1px solid #3B424C;
}
.smart-style-1 .joyride-step__holder .tour-title-input {
  color: #ffffff;
  background-clip: padding-box;
  background-color: #0C1214;
  border: 1px solid #3B424C;
}
.smart-style-1 .idTooltip {
  background: #000 0% 0% no-repeat padding-box;
  border: 1px solid #3B424C;
  color: #E2E2E2;
}
.smart-style-1 .netComID {
  color: #ffffff;
}
.smart-style-1 .dropdown-list .dropdown-list-items-contents {
  background-color: #0C1214 !important;
  border: 1px solid #3B424C !important;
}
.smart-style-1 .dropdown-list-row {
  color: #ffffff;
}
.smart-style-1 .dropdown-list-row .list-group-item {
  background-color: transparent !important;
}
.smart-style-1 .dropdown-list-row .list-group-item:hover {
  background: #8B9635 !important;
  color: #0C1214 !important;
}
.smart-style-1 .nav-tabs .nav-item.show .nav-link,
.smart-style-1 .nav-tabs .nav-link.active {
  color: #ffffff;
}
.smart-style-1 .nav-tabs .nav-item.show .nav-link,
.smart-style-1 .nav-tabs .nav-link {
  color: #ffffff;
}
.smart-style-1 .subscriberSelect .ng-select-container {
  background: #202124 !important;
  border: 1px solid #333333 !important;
  color: #ffffff;
  font-size: 12px;
  height: 45px;
}
.smart-style-1 .subscriberSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #202124 !important;
  border: 1px solid #333333 !important;
}
.smart-style-1 .subscriberSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #202124 !important;
  color: #ffffff;
  font-size: 13px !important;
}
.smart-style-1 #editMode select:not([multiple]) option {
  background: #202124;
}
.smart-style-1 .spinner-circle-swish {
  color: #ffffff !important;
}
.smart-style-1 .form-group label {
  color: #E2E2E2 !important;
}
.smart-style-1 #header.colorChangeTest .header-btn {
  background-color: transparent;
  box-shadow: none !important;
}
.smart-style-1 #header.colorChangeTest .header-btn:hover {
  background-color: #1E1E1E;
}
.smart-style-1 #header.colorChangeTest .dropdown-icon-menu:hover .header-btn {
  background-color: #1E1E1E;
}
.smart-style-1 .checkbox {
  color: #005397;
}
.smart-style-1 .card-header {
  background-color: rgba(0, 0, 0, 0.5);
}
.smart-style-1 .accordianClass {
  color: #ffffff;
}
.smart-style-1 #wizard {
  background-color: !important;
}
.smart-style-1 #wizard .line-1 {
  background: #666666;
}
.smart-style-1 #wizard .wizard-items {
  border: 1px solid #333333;
  background: #202124 0% 0% no-repeat padding-box;
}
.smart-style-1 #wizard .wizard-items:hover {
  background: #171819 0% 0% no-repeat padding-box;
}
.smart-style-1 #wizard .wizard-items .wizardDiv {
  border: 1px solid #404040;
  background: #404040 0% 0% no-repeat padding-box;
}
.smart-style-1 #wizard b.badges {
  border: 1px solid #202124;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}
.smart-style-1 #wizard .wizard-items .wizardDiv {
  border: 1px solid #404040;
  background: #404040 0% 0% no-repeat padding-box;
}
.smart-style-1 #wizard b.badges {
  border: 1px solid #202124;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}
.smart-style-1 #wizard .wizard-billeritem {
  border: 1px solid #333333;
  background: #202124 0% 0% no-repeat padding-box;
}
.smart-style-1 .docLbl {
  width: 100%;
  color: #ffffff;
  font-size: 14px;
}
.smart-style-1 .form-control {
  color: #ffffff !important;
  background-color: #202124 !important;
}
.smart-style-1 .docInput {
  width: 100%;
  margin-right: 10px;
  border-radius: 10px !important;
  background-color: #0C1214 !important;
  border: unset !important;
}
.smart-style-1 .common_wizardItemContainer .wizard_format {
  width: 50% !important;
}
.smart-style-1 .card-header {
  background-color: rgba(0, 0, 0, 0.5);
}
.smart-style-1 .card {
  background-color: #202124;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.smart-style-1 .ebillFormFormly label,
.smart-style-1 .disableForm label,
.smart-style-1 .ebillForm label {
  color: #ffffff !important;
}
.smart-style-1 .ebillFormFormly .form-control,
.smart-style-1 .disableForm .form-control,
.smart-style-1 .ebillForm .form-control {
  border: 1px solid #333333;
  color: #ffffff;
  height: 40px !important;
  border-radius: 6px !important;
  font-size: 14px;
}
.smart-style-1 .billTabSection .stepsinout-form.formly .form-control {
  color: #ffffff !important;
  background-color: #0C1214 !important;
  border-color: #0C1214;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-1 .billTabSection {
  border: 1px solid #333333;
}
.smart-style-1 .biller_tabSection .nav-item a {
  margin-right: unset !important;
  background-color: unset !important;
  color: #ffffff !important;
  border-bottom: unset !important;
  border-left-color: #333333 !important;
  border-right-color: #333333 !important;
  border-top-color: #333333 !important;
}
.smart-style-1 .btn-primary {
  background: transparent !important;
  border: 2px solid #8B9635;
  color: #ffffff !important;
  padding: 3px 15px;
  border-radius: 15px;
  font-size: 13px;
}
.smart-style-1 .btn-primary:hover {
  background: #8B9635 !important;
  color: #171717 !important;
  border: 2px solid #8B9635;
}
.smart-style-1 table.positionTable {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border: 1px solid #333333 !important;
  border-radius: 5px;
}
.smart-style-1 #wizard .wizard-companyitem {
  border: 1px solid #333333;
  background: #202124 0% 0% no-repeat padding-box;
}
.smart-style-1 .panelSector .ng-select .ng-select-container {
  background-color: transparent !important;
  color: #000 !important;
  border: 1px solid #333333 !important;
}
.smart-style-1 .companySelect .ng-select-container {
  color: #ffffff !important;
  height: 42px !important;
  border: none;
}
.smart-style-1 .companySelect .ng-select-container input {
  color: #ffffff !important;
}
.smart-style-1 #wizard {
  color: #ffffff;
}
.smart-style-1 #wizard {
  background-color: !important;
}
.smart-style-1 #wizard .line .line-1 {
  background: #666666;
}
.smart-style-1 #wizard .line11 .line-1 {
  background: transparent;
}
.smart-style-1 #wizard .wizard-items {
  border: 1px solid #333333;
  background: #202124 0% 0% no-repeat padding-box;
}
.smart-style-1 #wizard .wizard-items:hover {
  background: #171819 0% 0% no-repeat padding-box;
}
.smart-style-1 #wizard .wizard-items .wizardDiv {
  border: 1px solid #404040;
  background: #404040 0% 0% no-repeat padding-box;
}
.smart-style-1 #wizard b.badges {
  border: 1px solid #202124;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}
.smart-style-1 #wizard .wizard-billeritem {
  border: 1px solid #333333;
  background: #202124 0% 0% no-repeat padding-box;
}
.smart-style-1 #wizard .wizard-billeritem .wizardDiv {
  border: 1px solid #404040;
  background: #404040 0% 0% no-repeat padding-box;
}
.smart-style-1 .setselectHeight,
.smart-style-1 .manageHeader {
  color: #ffffff;
}
.smart-style-1 #overlay,
.smart-style-1 #overlay1,
.smart-style-1 #overlay2,
.smart-style-1 #overlay3 {
  background-color: rgba(0, 0, 0, 0.5);
}
.smart-style-1 .comapnyLbl {
  color: #ffffff !important;
}
.smart-style-1 .nav_btn {
  color: #ffffff !important;
}
.smart-style-1 .nav_btn:hover {
  color: #202427 !important;
  background: #8B9635 !important;
}
.smart-style-1 .nav-tabs > li.active > a.display-border,
.smart-style-1 .nav-tabs > li.active > a.display-border:focus,
.smart-style-1 .nav-tabs > li.active > a.display-border:hover {
  background-color: #171717 !important;
  border-top: 2px solid #8B9635 !important;
  border-left: 2px solid #8B9635 !important;
  border-right: 2px solid #8B9635 !important;
  color: #ffffff;
  box-shadow: none;
}
.smart-style-1 .interface-popup.nav-tabs > li {
  border-top: 2px solid #8B9635 !important;
  border-left: 2px solid #8B9635 !important;
  border-right: 2px solid #8B9635 !important;
  background-color: #171717 !important;
}
.smart-style-1 .interface-popup.nav-tabs > li a {
  background-color: transparent;
}
.smart-style-1 .interface-popup.nav-tabs > li.active > a {
  border: none !important;
  background: transparent !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li {
  border: 1px solid #3B424C;
  border-top: none !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li:hover {
  border: 1px solid #7c7b7b !important;
  border-top: none !important;
  background-color: #7c7b7b !important;
  box-shadow: 0 -2px 0 #7c7b7b !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li > a.invoice-tabhead {
  color: #ffffff !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li > a:hover {
  color: #ffffff !important;
  background-color: #7c7b7b !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li > a:focus {
  color: #ffffff !important;
  background-color: #7c7b7b !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li.active {
  border: none !important;
  box-shadow: 0 -2px 0 #8B9635 !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li.active:hover {
  background-color: transparent !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead {
  background-color: #202124 !important;
  color: #ffffff !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead:focus {
  background-color: #202124 !important;
  color: #ffffff !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead:hover {
  background-color: #202124 !important;
  color: #ffffff !important;
}
.smart-style-1 .invoice-tab-header {
  border: 1px solid #3B424C !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  background-color: #202124 !important;
  color: #ffffff !important;
}
.smart-style-1 .tooltipA .tooltiptext {
  background: #171717 0% 0% no-repeat padding-box;
  color: #E2E2E2;
  border: 1px solid #3B424C;
}
.smart-style-1 .headerContents {
  border-radius: 8px;
  border: 1px solid #333333 !important;
  overflow: hidden;
  background: #202124;
}
.smart-style-1 .algntblTranscation {
  border-radius: 8px;
  border: 1px solid #333333 !important;
  overflow: hidden;
  background: #202124 !important;
}
.smart-style-1 .commonBorderColor {
  border: 1px solid #3B424C !important;
}
.smart-style-1 table.dataTable tbody tr.selected {
  background-color: #8B9635 !important;
  border-color: #cccccc7a;
  color: #171717 !important;
}
.smart-style-1 table.dataTable tbody tr.selected:hover {
  background-color: #8B9635 !important;
  border-color: #cccccc7a;
}
.smart-style-1 .borderHead {
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid #333333 !important;
}
.smart-style-1 .headerContent {
  border-radius: 10px;
  border: 1px solid #333333;
  background-color: #202124 !important;
}
.smart-style-1 .filter-top {
  color: #ffffff !important;
}
.smart-style-1 .setwhiteFont {
  color: #ffffff;
}
.smart-style-1 .ediLbl {
  color: #ffffff;
}
.smart-style-1 .app-tut.active {
  background-color: #7c7b7b !important;
}
.smart-style-1 .ordr-invc-div.active {
  background-color: #8B9635 !important;
}
.smart-style-1 .ordr-invc-div.active .ordr-invc-img img {
  filter: brightness(0) invert(0) !important;
}
.smart-style-1 .ordr-invc-div.active .ordr-invc-title {
  color: #000 !important;
}
.smart-style-1 .snd-rcv-div.active {
  background-color: #8B9635 !important;
}
.smart-style-1 .snd-rcv-div.active .snd-rcv-img img {
  filter: brightness(0) invert(0) !important;
}
.smart-style-1 .snd-rcv-div.active .snd-rcv-title {
  color: #000 !important;
}
.smart-style-1 .app-tut {
  background-color: #202124;
}
.smart-style-1 .app-tut:not(.nohover):hover {
  background-color: #7c7b7b !important;
}
.smart-style-1 .snd-rcv-div {
  border: 1px solid #8B9635 !important;
}
.smart-style-1 .snd-rcv-div:hover {
  background-color: #8B9635 !important;
}
.smart-style-1 .snd-rcv-div:hover .snd-rcv-img img {
  filter: brightness(0) invert(0) !important;
}
.smart-style-1 .snd-rcv-div:hover .snd-rcv-title {
  color: #000 !important;
}
.smart-style-1 .ordr-invc-div {
  border: 1px solid #8B9635 !important;
}
.smart-style-1 .ordr-invc-div:not(.nohover):hover {
  background-color: #8B9635 !important;
}
.smart-style-1 .ordr-invc-div:not(.nohover):hover .ordr-invc-img img {
  filter: brightness(0) invert(0) !important;
}
.smart-style-1 .ordr-invc-div:not(.nohover):hover .ordr-invc-title {
  color: #000 !important;
}
.smart-style-1 .successcmpimgDiv {
  background-color: #0C1214;
}
.smart-style-1 .snd-rcv-img img {
  filter: brightness(0) invert(1);
}
.smart-style-1 .ordr-invc-img img {
  filter: brightness(0) invert(1);
}
.smart-style-1 hr.search-hr {
  border-top: 1px solid #3B424C;
}
.smart-style-1 #first-row .setup-info .stepper .step-items .step-d {
  background-color: #8B9635;
  opacity: 0.5;
}
.smart-style-1 #first-row .setup-info .stepper .step-items .process-info .step-title {
  border: 3px solid #333333;
  background-color: #202124;
}
.smart-style-1 #first-row .setup-info .stepper .step-items .process-info .step-connector::before {
  border: 1px solid #333333;
}
.smart-style-1 #first-row .setup-info .stepper .step-items .process-info .step-connector::after {
  border: 1px solid #333333;
}
.smart-style-1 #first-row .setup-info .stepper .step-items.preceedings .step-d {
  background-color: #8B9635;
  opacity: 1;
}
.smart-style-1 #first-row .setup-info .stepper .step-items.preceedings .step-title {
  background-color: #8B9635 !important;
  border: 3px solid #8B9635 !important;
}
.smart-style-1 #first-row .setup-info .stepper .step-items.preceedings .step-connector::before {
  border: 1px solid #8B9635 !important;
}
.smart-style-1 #first-row .setup-info .stepper .step-items.preceedings .step-connector::after {
  border: 1px solid #8B9635 !important;
}
.smart-style-1 #first-row .setup-info .stepper .step-items.active .step-d {
  opacity: 1 !important;
}
.smart-style-1 #first-row .setup-info .stepper .step-items.active .step-title {
  background-color: #202124;
  border: 3px solid #8B9635 !important;
}
.smart-style-1 #first-row .setup-info .stepper .step-items.active .step-connector::before {
  border: 1px solid #8B9635 !important;
}
.smart-style-1 #first-row .setup-info .stepper .step-items.active .step-connector::after {
  border: 1px solid #333333;
}
.smart-style-1 .confirmebillLabel {
  color: #fff;
}
.smart-style-1 .checkmark {
  border: 2px solid #ffffff;
}
.smart-style-1 .checkbox-container .checkmark:after {
  left: 7px;
  top: 1px;
  width: 8px;
  height: 16px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.smart-style-1 .iom_btn,
.smart-style-1 .ebill_btn {
  color: #ffffff;
}
.smart-style-1 .iom_btn:hover,
.smart-style-1 .ebill_btn:hover {
  color: #171717 !important;
}
.smart-style-1 .ac_ConnectionSection .ac_div {
  color: #ffffff;
}
.smart-style-1 .ac_ConnectionSection .ac_typeDiv {
  color: #ffffff;
}
.smart-style-1 .ac_ConnectionSection .ac_typeDiv .common_detail {
  color: #8B9635;
}
.smart-style-1 .ac_ConnectionSection .fillGreen {
  color: #000 !important;
}
.smart-style-1 .footer-menu {
  color: #ffffff !important;
}
.smart-style-1 .company-label {
  color: #ffffff;
}
.smart-style-1 .module-name {
  color: #ffffff;
}
.smart-style-1 .footer-menu {
  color: #ffffff !important;
}
.smart-style-1 .chatIcon {
  filter: brightness(0) invert(1);
}
.smart-style-1 .fillGreen {
  color: #000 !important;
}
.smart-style-1 .companyTooltip {
  background: #171717 0% 0% no-repeat padding-box;
  border: 1px solid #3B424C;
  color: #E2E2E2;
}
/*
---------------------------------------------------------------------------------------
    End   Dark theme style
---------------------------------------------------------------------------------------
*/
/*
---------------------------------------------------------------------------------------
    White theme style
---------------------------------------------------------------------------------------
*/
.smart-style-2 {
  /* CSS for edi dashboard */
  /* CSS for edi dashboard */
}
.smart-style-2 .applicationHead {
  height: 34px;
  background: #ffffff 0% 0% no-repeat padding-box;
  color: black !important;
  opacity: 1;
  border: unset;
  margin-left: 5px;
  margin-right: -5px;
}
.smart-style-2 #left-panel {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 10px !important;
}
.smart-style-2 #left-panel nav ul li {
  border-bottom: 1px solid #8B9635;
}
.smart-style-2 #left-panel nav ul li:hover {
  background: #8B9635 !important;
  color: #ffffff !important;
  border: 1px solid #3B3B3B33;
}
.smart-style-2 #left-panel nav ul li.active {
  background-color: #8B9635 !important;
}
.smart-style-2 #left-panel nav ul li.active a {
  color: #fff !important;
}
.smart-style-2 #left-panel nav ul li.active ul li {
  color: #202427 !important;
}
.smart-style-2 #left-panel nav ul li.active ul li:hover {
  color: #ffffff !important;
}
.smart-style-2 #left-panel nav ul li.active ul li:hover a {
  color: #ffffff !important;
  background: #8B9635 !important;
}
.smart-style-2 #left-panel nav ul li.active ul li a {
  color: #4c4f53 !important;
}
.smart-style-2 #left-panel nav ul li.active ul li.active a {
  color: #ffffff !important;
}
.smart-style-2 #left-panel nav ul li.open li:hover a {
  color: #ffffff !important;
}
.smart-style-2 nav ul li.active a {
  font-weight: 400;
}
.smart-style-2 nav ul li.active a:before {
  color: #202427;
}
.smart-style-2 nav ul li a {
  color: #4c4f53 !important;
  padding: 12px 17px 12px 16px !important;
  font-size: 16px !important;
}
.smart-style-2 nav ul li a:focus, .smart-style-2 nav ul li a:hover {
  color: #ffffff !important;
  background: #8B9635 !important;
}
.smart-style-2 nav ul li a i {
  font-size: 17px !important;
  vertical-align: 0 !important;
}
.smart-style-2 nav ul li.open a {
  color: #4c4f53 !important;
  font-weight: 400;
}
.smart-style-2 nav ul li.open:focus, .smart-style-2 nav ul li.open:hover {
  color: #ffffff !important;
  background: #8B9635 !important;
}
.smart-style-2 nav ul li.open a b {
  color: #4c4f53 !important;
}
.smart-style-2 nav ul li.open.active a b {
  color: #4c4f53 !important;
}
.smart-style-2 nav ul li.open:hover > a {
  color: #ffffff !important;
}
.smart-style-2 nav ul li.open:hover > a b {
  color: #ffffff !important;
}
.smart-style-2 nav ul ul li {
  background-color: #dbdbdb;
}
.smart-style-2 nav ul ul li a {
  padding-left: 43px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  color: #ffffff;
  font-size: 14px !important;
}
.smart-style-2 nav ul ul li a:focus, .smart-style-2 nav ul ul li a:hover {
  color: #202427 !important;
  background: #8B9635 !important;
}
.smart-style-2 nav ul li.active > a:before {
  content: unset !important;
}
.smart-style-2 .notification-body .unread {
  background-color: #EDEDED;
}
.smart-style-2 .notification-body .from {
  color: #4c4f53 !important;
}
.smart-style-2 .notification-body .msg-body {
  color: #7A7A7A !important;
}
.smart-style-2 .notification-body .activityTime {
  color: #7A7A7A !important;
}
.smart-style-2 .notification-body li span {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .ajax-dropdown {
  border: 1px solid #d9d9d9 !important;
}
.smart-style-2 #fullscreen .svg-icon {
  background-image: url('Path222.faed6ac4229dbddce176.svg');
}
.smart-style-2 #header .notification-svg {
  background-image: url('39-Notification.a91267442bae57b87b90.svg');
}
.smart-style-2 #header #activity.activity-svg {
  background-image: url('Page-1.06157606d3196914b46c.svg');
}
.smart-style-2 #header .collapseMenu .collaspe-menu-svg {
  background-image: url('Path 224.55a5f2da498c377ed45f.svg');
}
.smart-style-2 #header .collapseMenu .collaspe-menu-sub-svg {
  background-image: url('Group224.649ca2f656deeb39e0a7.svg');
}
.smart-style-2 #header .collapseMenu:hover {
  background-color: #cccccc7a;
}
.smart-style-2 #header .header-btn {
  box-shadow: none;
}
.smart-style-2 #header .header-btn-list a {
  box-shadow: none;
}
.smart-style-2 #header .menu-item:not(:last-child) {
  border: unset !important;
}
.smart-style-2 #header .menu-item .webComponetsBorder {
  border: none;
}
.smart-style-2 #header .menu-item:hover {
  background-color: #cccccc7a;
}
.smart-style-2 #header .menu-item .webComponetsHoverColor:hover {
  background-color: #cccccc7a;
}
.smart-style-2 #header .menu-item.no-hover:hover {
  background-color: #EDEDED;
}
.smart-style-2 .header-dropdown-list a.dropdown-toggle {
  color: #4c4f53 !important;
}
.smart-style-2 .header-dropdown-list .dropdown-menu li a:hover {
  background-color: #EDEDED !important;
}
.smart-style-2 .header-dropdown-list .dropdown-menu .active a {
  background-color: #EDEDED !important;
}
.smart-style-2 .theme-reverse-text-color {
  color: #0C1214 !important;
  color: #ffffff !important;
}
.smart-style-2 .theme-icon {
  color: #4c4f53 !important;
}
.smart-style-2 .theme-header-text-color {
  color: #4c4f53 !important;
}
.smart-style-2 .theme-subtext-color {
  color: #7A7A7A !important;
}
.smart-style-2 .dashboard .jarviswidget div {
  background-color: #F7F7F7 !important;
  border: 1px solid #cccccc7a !important;
}
.smart-style-2 #ribbon .breadcrumb li {
  color: #69737A;
}
.smart-style-2 #ribbon .breadcrumb li:last-child {
  color: #4c4f53 !important;
}
.smart-style-2 .border {
  border: 1px solid #cccccc7a !important;
  border-radius: 10px;
}
.smart-style-2 .jarviswidget-color-darken header {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: none;
}
.smart-style-2 .theme-bg-color {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .theme-header-color {
  color: #4c4f53 !important;
}
.smart-style-2 .footerTextColor {
  color: #ffffff !important;
}
.smart-style-2 .login-info {
  color: #212529 !important;
}
.smart-style-2 #content {
  background: #ffffff !important;
}
.smart-style-2 .menuIconsOption img {
  filter: brightness(100) invert(1);
}
.smart-style-2 .menuSelected {
  background-color: #666666 !important;
}
.smart-style-2 nav ul li.active > a:before {
  content: unset !important;
}
.smart-style-2 .dropdown-icon-menu > ul > li .btn,
.smart-style-2 .header-btn {
  background: none;
}
.smart-style-2 .jarviswidget > div {
  border-right-color: #ffffff !important;
  border-bottom-color: #ffffff !important;
  border-left-color: #ffffff !important;
}
.smart-style-2 .editButton {
  font-size: 37px;
  text-align: center;
  font: normal normal normal 30px/41px Open Sans;
  letter-spacing: -0.3px;
  background-color: #ffffff;
  border: 2px solid #8B9635;
  color: #202528;
  border-radius: 19px;
  font-size: 13px;
  top: 55px;
  position: relative;
  float: right;
  width: 155px;
  height: 34px;
  right: 220px;
}
.smart-style-2 .editButton:hover {
  background: #8B9635 !important;
  color: #0C1214 !important;
}
.smart-style-2 .commonHead {
  background: #ffffff !important;
  color: #0c1214 !important;
}
.smart-style-2 .headData {
  background-color: #ffffff;
  color: #4c4f53;
  margin-top: 10px;
}
.smart-style-2 .pagination > .active > a {
  background-color: #8B9635 !important;
  border-color: #8B9635 !important;
  color: #ffffff;
}
.smart-style-2 .pagination > li > a {
  background-color: #ffffff !important;
  color: #171717;
}
.smart-style-2 .pagination > li:first-child > a,
.smart-style-2 .pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #ffffff !important;
}
.smart-style-2 .pagination > li:last-child > a,
.smart-style-2 .pagination > li:last-child > span {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #ffffff !important;
}
.smart-style-2 .dt-toolbar-footer {
  background: none !important;
  font-size: none !important;
  overflow: hidden;
  padding: none !important;
  border-top: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important;
  width: 100%;
}
.smart-style-2 table.positionTable {
  border: 1px solid #cccccc7a !important;
  margin-top: 0px !important;
  border-radius: 5px;
}
.smart-style-2 .btn-primary {
  color: #202124 !important;
  border-color: #8B9635 !important;
}
.smart-style-2 .form-control {
  background-color: transparent;
  border: 1px solid #cccccc7a;
  color: #000;
}
.smart-style-2 .iom-button {
  color: #000;
}
.smart-style-2 .input-group-text {
  color: #000;
  border: 1px solid #EEEEEE;
  border-radius: 0.25rem;
  background-color: #EEEEEE;
}
.smart-style-2 .ui-datepicker td .ui-state-highlight {
  color: #ffffff;
}
.smart-style-2 .ui-datepicker td .ui-state-default {
  color: #707070;
}
.smart-style-2 .ui-datepicker td .ui-state-hover {
  color: #ffffff;
}
.smart-style-2 .ui-datepicker td .ui-datepicker-header {
  background-color: #ffffff;
}
.smart-style-2 .table-hover tbody tr:not(.selected):hover {
  color: #171717 !important;
  background-color: #ededed !important;
}
.smart-style-2 table.dataTable tbody tr.selected {
  background-color: #8B9635 !important;
  border-color: #cccccc7a;
  color: #ffffff !important;
}
.smart-style-2 table.dataTable tbody tr.selected:hover {
  background-color: #8B9635 !important;
  border-color: #cccccc7a;
}
.smart-style-2 .darkHeaderIcon,
.smart-style-2 .lightHeaderIcon {
  color: #171717 !important;
}
.smart-style-2 .editPaymentHeader {
  color: #171717 !important;
  font-size: 16px !important;
}
.smart-style-2 .payContent {
  background: #F7F7F7 !important;
}
.smart-style-2 .btnModalSave {
  float: right;
  background-color: #ffffff;
  border: none;
  border-radius: 15px;
  border: 2px solid #8B9635;
}
.smart-style-2 .btnModalClose {
  float: left;
  background-color: #ffffff;
  border: none;
  border-radius: 15px;
  border: 2px solid #8B9635 !important;
}
.smart-style-2 .exportBtn-help h1,
.smart-style-2 .exportBtn-help-table h1 {
  color: #2c2c2c !important;
}
.smart-style-2 .searchTableContacts.exportBtn-help,
.smart-style-2 .searchTable.exportBtn-help,
.smart-style-2 .powerd-by .exportBtn-help,
.smart-style-2 .downloadCenter .exportBtn-help,
.smart-style-2 .exportBtn-help-table,
.smart-style-2 .downloadCenter .exportBtn-help-download {
  color: #2c2c2c !important;
}
.smart-style-2 .searchTableContacts.exportBtn-help:before,
.smart-style-2 .searchTable.exportBtn-help:before {
  border-right-color: #ffffff;
}
.smart-style-2 .successDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .successDiv-popup {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .successDiv-popup-accept {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .theme-content-color {
  color: #666666;
}
.smart-style-2 .MessageBoxButtonSection button {
  color: #171717 !important;
}
.smart-style-2 .cancellabel {
  border: 1px solid #cccccc7a !important;
  background-color: #ffffff;
}
.smart-style-2 textarea.in {
  color: #171717 !important;
  background-color: #F7F7F7 !important;
  border: 1px solid #cccccc7a;
}
.smart-style-2 .tour-title-input {
  color: #171717 !important;
  background-clip: padding-box;
  background-color: #F7F7F7 !important;
  border: 1px solid #cccccc7a;
}
.smart-style-2 .joyride-step__holder {
  background-color: #EDEDED !important;
  box-shadow: 0 0 0px 1px #EDEDED !important;
}
.smart-style-2 .joyride-step__holder .joyride-step__container {
  background-color: #EDEDED !important;
  box-shadow: 0 0 0px 1px #EDEDED !important;
}
.smart-style-2 .joyride-step__holder .joyride-arrow__top {
  border-bottom: 11px solid #EDEDED !important;
}
.smart-style-2 .joyride-step__holder .joyride-button {
  color: #0C1214 !important;
}
.smart-style-2 .joyride-step__holder .joyride-button:hover {
  background-color: #8B9635 !important;
  color: #0C1214 !important;
}
.smart-style-2 .joyride-step__holder .joyride-step__title,
.smart-style-2 .joyride-step__holder .joyride-step__body {
  color: #4c4f53 !important;
}
.smart-style-2 .btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
}
.smart-style-2 .btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
}
.smart-style-2 .idTooltip {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #EDEDED;
  color: #4c4f53;
}
.smart-style-2 .netComID {
  color: #000;
}
.smart-style-2 .dropdown-list .dropdown-list-items-contents {
  background-color: #F7F7F7 !important;
  border: 1px solid #cccccc7a !important;
}
.smart-style-2 .dropdown-list-row {
  color: #0C1214;
}
.smart-style-2 .dropdown-list-row .list-group-item {
  background-color: transparent !important;
}
.smart-style-2 .dropdown-list-row .list-group-item:hover {
  background: #8B9635 !important;
  color: #0C1214 !important;
}
.smart-style-2 .billerDetailSection .nav-tabs > li > a {
  color: #000 !important;
}
.smart-style-2 .companySelect .ng-select-container {
  background: #EEE !important;
  border: 1px solid #333333;
}
.smart-style-2 .companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #f5faff !important;
  border: none !important;
  color: #000 !important;
  font-size: 13px !important;
}
.smart-style-2 .companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: transparent !important;
  color: #000 !important;
}
.smart-style-2 .companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: #ebf5ff !important;
}
.smart-style-2 .topMenuLeft {
  background-color: #CCCC;
}
.smart-style-2 .topMenuLeft:hover {
  background-color: #6666;
}
.smart-style-2 .iom_btn,
.smart-style-2 .ebill_btn {
  color: #171717;
}
.smart-style-2 .iom_btn:hover,
.smart-style-2 .ebill_btn:hover {
  color: #fff !important;
}
.smart-style-2 .ebillForm .form-control {
  background-color: #EEE !important;
}
.smart-style-2 .ebillForm formly-field-select select {
  background-color: #EEE !important;
}
.smart-style-2 .ebillForm formly-field-select select option {
  background-color: #EEE !important;
}
.smart-style-2 .form-control {
  background-color: transparent !important;
}
.smart-style-2 select {
  background-color: #EEE !important;
}
.smart-style-2 select option {
  background-color: #EEE !important;
}
.smart-style-2 .subscriberSelect .ng-select-container {
  background: #EEEEEE !important;
  font-size: 12px;
  border-radius: 10px !important;
  height: 45px;
}
.smart-style-2 .subscriberSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #EEEEEE !important;
  font-size: 13px !important;
}
.smart-style-2 .successdeleteDiv {
  background-color: #EEE !important;
}
.smart-style-2 .fa-refresh {
  color: #333;
}
.smart-style-2 .lblWhite {
  color: #0C1214 !important;
}
.smart-style-2 .lstLbl {
  color: #0C1214 !important;
}
.smart-style-2 .detailLbl {
  color: #0C1214 !important;
}
.smart-style-2 .spinner-circle-swish {
  color: #0C1214 !important;
}
.smart-style-2 .form-group label {
  color: #0C1214 !important;
}
.smart-style-2 #header.colorChangeTest .topMenuItem {
  color: #666666 !important;
}
.smart-style-2 #header.colorChangeTest .topMenuLeft {
  color: #666666 !important;
  background-color: transparent;
}
.smart-style-2 #header.colorChangeTest .topMenuLeft:hover {
  background-color: #cccccc7a;
}
.smart-style-2 #header.colorChangeTest .topMenuLeft:hover .topMenuItem {
  color: #ffffff !important;
}
.smart-style-2 #header.colorChangeTest #logo {
  filter: contrast(0%);
}
.smart-style-2 #header.colorChangeTest .menuSelected .topMenuItem {
  color: #ffffff !important;
}
.smart-style-2 #header.colorChangeTest .topMenuLeft.menuSelected:hover {
  background-color: transparent;
}
.smart-style-2 .ebillFormFormly formly-field-select select option,
.smart-style-2 .disableForm formly-field-select select option,
.smart-style-2 .ebillForm formly-field-select select option {
  background-color: unset !important;
}
.smart-style-2 .ebillFormFormly .form-control,
.smart-style-2 .disableForm .form-control,
.smart-style-2 .ebillForm .form-control {
  background: #ffffff !important;
  border: 1px solid #333333 !important;
  color: #000;
  height: 40px !important;
  border-radius: 6px !important;
  font-size: 14px;
}
.smart-style-2 #wizard .wizard-items {
  border: 1px solid #cccccc7a;
  background: #ffffff 0% 0% no-repeat padding-box;
}
.smart-style-2 #wizard .wizard-billeritem {
  border: 1px solid #cccccc7a;
  background: #ffffff 0% 0% no-repeat padding-box;
}
.smart-style-2 #wizard .wizard-items .wizardDiv {
  border: 1px solid transparent;
  background: #cccccc7a 0% 0% no-repeat padding-box;
}
.smart-style-2 .form-control:disabled {
  background-color: #EEEEEE !important;
}
.smart-style-2 .docInput {
  width: 100%;
  margin-right: 10px;
  border-radius: 10px !important;
  background-color: #EEEEEE !important;
}
.smart-style-2 #wizard .wizard-companyitem {
  border: 1px solid #cccccc7a;
  background: #ffffff 0% 0% no-repeat padding-box;
}
.smart-style-2 .docLbl {
  width: 100%;
  color: #000;
  font-size: 14px;
}
.smart-style-2 #wizard .line-1 {
  background: #cccccc7a;
}
.smart-style-2 .billTabSection {
  border: 1px solid #ced4da;
}
.smart-style-2 .ebillFormFormly formly-field-select select option,
.smart-style-2 .disableForm formly-field-select select option,
.smart-style-2 .ebillForm formly-field-select select option {
  background-color: unset !important;
}
.smart-style-2 .ebillFormFormly .form-control,
.smart-style-2 .disableForm .form-control,
.smart-style-2 .ebillForm .form-control {
  background: #ffffff !important;
  border: 1px solid #cccccc7a !important;
  color: #000;
  height: 40px !important;
  border-radius: 6px !important;
  font-size: 14px;
}
.smart-style-2 #wizard {
  color: #666666;
}
.smart-style-2 .docInput {
  width: 100%;
  margin-right: 10px;
  border-radius: 10px !important;
  background-color: #EEEEEE !important;
}
.smart-style-2 .btn-primary {
  background: #ffffff;
  border: 2px solid #8B9635;
  color: #202124 !important;
  padding: 3px 15px;
  border-radius: 15px;
  font-size: 13px;
}
.smart-style-2 .btn-primary:hover {
  color: #ffffff !important;
}
.smart-style-2 .common_wizardItemContainer .wizard_format {
  width: 55% !important;
}
.smart-style-2 .companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 13px !important;
}
.smart-style-2 .panelSector .ng-select .ng-select-container {
  background-color: transparent !important;
  color: #000 !important;
  border: 1px solid #cccccc7a !important;
}
.smart-style-2 .companySelect .ng-select-container {
  border-radius: 10px !important;
  color: #000 !important;
  height: 42px !important;
  border: none;
  font-size: 12px !important;
}
.smart-style-2 .nav-tabs li {
  float: left;
  margin-bottom: -1px;
  text-align: center;
  border-bottom: 1px solid #cccccc7a !important;
}
.smart-style-2 .biller_tabSection .nav-item {
  width: auto !important;
}
.smart-style-2 .biller_tabSection .nav-item a {
  margin-right: unset !important;
  background-color: unset !important;
  color: #000 !important;
  border-bottom: unset !important;
  border-left-color: #6666 !important;
  border-right-color: #6666 !important;
  border-top-color: #333333 !important;
}
.smart-style-2 #wizard .line .line-1 {
  background: #cccccc7a;
}
.smart-style-2 #wizard .line11 .line-1 {
  background: transparent;
}
.smart-style-2 #wizard .wizard-items {
  border: 1px solid #cccccc7a;
  background: #ffffff 0% 0% no-repeat padding-box;
}
.smart-style-2 #wizard .wizard-items:hover {
  background: #ededed 0% 0% no-repeat padding-box;
}
.smart-style-2 #wizard .wizard-items .wizardDiv {
  border: 1px solid transparent;
  background: #cccccc7a 0% 0% no-repeat padding-box;
}
.smart-style-2 #wizard .wizard-items .wizardDiv span {
  color: #0C1214;
}
.smart-style-2 #wizard .wizard-billeritem {
  border: 1px solid #cccccc7a;
  background: #ffffff 0% 0% no-repeat padding-box;
}
.smart-style-2 #wizard .wizard-billeritem .wizardDiv {
  border: 1px solid transparent;
  background: #cccccc7a 0% 0% no-repeat padding-box;
}
.smart-style-2 #wizard .wizard-billeritem .wizardDiv span {
  color: #0C1214;
}
.smart-style-2 #wizard b.badges {
  border: 1px solid #ffffff;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}
.smart-style-2 .common_wizardItemContainer .wizard_format {
  width: 50% !important;
}
.smart-style-2 .setselectHeight,
.smart-style-2 .businessLbl {
  color: #000 !important;
}
.smart-style-2 #overlay,
.smart-style-2 #overlay1,
.smart-style-2 #overlay2,
.smart-style-2 #overlay3 {
  background: #F7F7F7 0, 0, 0, 0.5;
}
.smart-style-2 .comapnyLbl {
  color: #000 !important;
}
.smart-style-2 .nav_btn {
  color: #4c4f53 !important;
}
.smart-style-2 .nav_btn:hover {
  color: #ffffff !important;
  background: #8B9635 !important;
}
.smart-style-2 .nav_btn:hover .fa-refresh {
  color: #ffffff !important;
}
.smart-style-2 .nav_btn .fa-refresh {
  color: #171717 !important;
}
.smart-style-2 .nav_btn .fa-refresh:hover {
  color: #ffffff !important;
}
.smart-style-2 .headerContents {
  border-radius: 8px;
  border: 1px solid #cccccc7a !important;
  overflow: hidden;
}
.smart-style-2 .algntblTranscation {
  padding: 0 0 0 7px;
  border-radius: 8px;
  border: 1px solid #cccccc7a;
  margin: 0 3px !important;
  overflow: hidden;
}
.smart-style-2 .selected {
  background-color: #8B9635 !important;
  border-color: #8B9635 !important;
}
.smart-style-2 .selected:hover {
  color: #171717 !important;
}
.smart-style-2 .commonBorderColor {
  border: 1px solid #cccccc7a !important;
}
.smart-style-2 .nav-tabs > li.active > a.display-border,
.smart-style-2 .nav-tabs > li.active > a.display-border:focus,
.smart-style-2 .nav-tabs > li.active > a.display-border:hover {
  background-color: #ffffff !important;
  border-top: 2px solid #8B9635 !important;
  border-left: 2px solid #8B9635 !important;
  border-right: 2px solid #8B9635 !important;
  color: #000;
}
.smart-style-2 .nav-tabs.invoice-tab > li.active {
  border-bottom: none !important;
  box-shadow: 0 -2px 0 #8B9635 !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li.active:hover {
  background-color: transparent !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead {
  background-color: transparent !important;
  color: #171717 !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead:focus {
  background-color: #ffffff !important;
  color: #171717 !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead:hover {
  background-color: #ffffff !important;
  color: #171717 !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li {
  border-bottom: 1px solid #cccccc7a !important;
  border-right: 1px solid #cccccc7a !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li:last-child {
  border-right: none !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li:hover {
  border-bottom: 1px solid #cccccc7a !important;
  border-top: none !important;
  background-color: #cccccc7a !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li > a:hover {
  color: #171717 !important;
}
.smart-style-2 .invoice-tab-header {
  border: 1px solid #EDEDED !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}
.smart-style-2 .setCircleTest {
  color: #F1DA91;
  font-size: 10px !important;
}
.smart-style-2 .tooltipA .tooltiptext {
  background: #F7F7F7 0% 0% no-repeat padding-box;
  color: #4c4f53;
  border: 1px solid #EDEDED;
}
.smart-style-2 .jarviswidget header {
  border: none !important;
}
.smart-style-2 .borderHead {
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid #cccccc7a !important;
}
.smart-style-2 .headerContent {
  border-radius: 10px;
  border: 1px solid #cccccc7a;
}
.smart-style-2 .app-tut {
  background-color: #ededed !important;
}
.smart-style-2 .app-tut.active {
  background-color: #CCC !important;
}
.smart-style-2 .ordr-invc-div.active {
  background-color: #8B9635 !important;
}
.smart-style-2 .snd-rcv-div.active {
  background-color: #8B9635 !important;
}
.smart-style-2 .app-tut {
  background-color: #202124;
}
.smart-style-2 .app-tut:not(.nohover):hover {
  background-color: #CCC !important;
}
.smart-style-2 .snd-rcv-div {
  border: 1px solid #8B9635 !important;
}
.smart-style-2 .snd-rcv-div:hover {
  background-color: #8B9635 !important;
}
.smart-style-2 .ordr-invc-div {
  border: 1px solid #8B9635 !important;
}
.smart-style-2 .ordr-invc-div:not(.nohover):hover {
  background-color: #8B9635 !important;
}
.smart-style-2 .step {
  border: 2px solid #cccccc7a !important;
  background: #cccccc7a !important;
}
.smart-style-2 .successcmpimgDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .trapezium {
  border-bottom: 34px solid #ccc !important;
}
.smart-style-2 .ordr-invc-div .ordr-invc-img img {
  filter: brightness(0) invert(0) !important;
}
.smart-style-2 .modal-footer {
  padding: 20px 0px 0px 0px;
  border-top: none;
}
.smart-style-2 hr.search-hr {
  border-top: 1px solid #cccccc7a !important;
}
.smart-style-2 .ordr-invc-div.disabled {
  color: #999 !important;
}
.smart-style-2 .ordr-invc-div.disabled .ordr-invc-img img {
  opacity: 0.25 !important;
}
.smart-style-2 .setgreenFont {
  color: #474d22 !important;
}
.smart-style-2 img.infoIcon.infoIcon-color {
  filter: brightness(0) saturate(100%) invert(72%) sepia(13%) saturate(4773%) hue-rotate(23deg) brightness(83%) contrast(58%);
}
.smart-style-2 img.infoIcon {
  filter: brightness(0) saturate(100%) invert(72%) sepia(13%) saturate(4773%) hue-rotate(23deg) brightness(83%) contrast(58%);
}
.smart-style-2 .table > tbody > tr > td,
.smart-style-2 .table > tbody > tr > th {
  border-top: 1px solid #dddddd5e !important;
}
.smart-style-2 #first-row .setup-info .stepper .step-items .step-d {
  background-color: #8B9635;
  border: 2px solid #8B9635 !important;
  opacity: 0.5;
}
.smart-style-2 #first-row .setup-info .stepper .step-items span.step-number {
  color: #fff;
}
.smart-style-2 #first-row .setup-info .stepper .step-items .process-info .step-title {
  border: 3px solid #cccccc7a;
  background-color: #ffffff;
}
.smart-style-2 #first-row .setup-info .stepper .step-items .process-info .step-connector::before {
  border: 1px solid #cccccc7a;
}
.smart-style-2 #first-row .setup-info .stepper .step-items .process-info .step-connector::after {
  border: 1px solid #cccccc7a;
}
.smart-style-2 #first-row .setup-info .stepper .step-items.preceedings .step-d {
  background-color: #8B9635;
  opacity: 1;
}
.smart-style-2 #first-row .setup-info .stepper .step-items.preceedings .step-title {
  background-color: #8B9635 !important;
  border: 3px solid #8B9635 !important;
}
.smart-style-2 #first-row .setup-info .stepper .step-items.preceedings .step-connector::before {
  border: 1px solid #8B9635 !important;
}
.smart-style-2 #first-row .setup-info .stepper .step-items.preceedings .step-connector::after {
  border: 1px solid #8B9635 !important;
}
.smart-style-2 #first-row .setup-info .stepper .step-items.active .step-d {
  opacity: 1 !important;
}
.smart-style-2 #first-row .setup-info .stepper .step-items.active .step-title {
  background-color: #ffffff;
  border: 3px solid #8B9635 !important;
}
.smart-style-2 #first-row .setup-info .stepper .step-items.active .step-connector::before {
  border: 1px solid #8B9635 !important;
}
.smart-style-2 #first-row .setup-info .stepper .step-items.active .step-connector::after {
  border: 1px solid #cccccc7a;
}
.smart-style-2 .confirmebillLabel {
  color: #000;
}
.smart-style-2 .checkmark {
  border: 2px solid #666666;
}
.smart-style-2 .checkbox-container .checkmark:after {
  left: 7px;
  top: 1px;
  width: 8px;
  height: 16px;
  border: solid #959595;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.smart-style-2 .ac_ConnectionSection .ac_div {
  color: #4c4f53;
}
.smart-style-2 .ac_ConnectionSection .ac_typeDiv {
  color: #4c4f53;
}
.smart-style-2 .ac_ConnectionSection .ac_typeDiv .common_detail {
  color: #8B9635;
}
.smart-style-2 .ac_ConnectionSection .fillGreen {
  color: #ffffff !important;
}
.smart-style-2 .company-label {
  color: #4c4f53;
}
.smart-style-2 .iom_btn,
.smart-style-2 .ebill_btn {
  color: #000;
}
.smart-style-2 .iom_btn:hover,
.smart-style-2 .ebill_btn:hover {
  color: #ffffff !important;
}
.smart-style-2 .module-name {
  color: #4c4f53;
}
.smart-style-2 .footer-menu {
  color: #4c4f53 !important;
}
.smart-style-2 .fillGreen {
  color: #ffffff !important;
}
.smart-style-2 .companyTooltip {
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border: 1px solid #EDEDED;
  color: #4c4f53;
}
/*
---------------------------------------------------------------------------------------
    End White theme style
---------------------------------------------------------------------------------------
*/
.invoiceListTable td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.invoiceCompanyTooltip {
  background-color: black;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  padding: 2px 2px;
  word-break: break-all;
  z-index: 1;
  white-space: normal;
  position: absolute;
  min-width: 250px;
}
.infoIcon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(72%) sepia(13%) saturate(4773%) hue-rotate(23deg) brightness(83%) contrast(58%);
}
.smart-style-1 .jarviswidget > div {
  background-color: #202124 !important;
  border: none;
}
.smart-style-1 .jarviswidget > div {
  border: none;
}
.jarviswidget .widget-body {
  min-height: 100px;
  position: relative;
  padding-bottom: 13px;
}
.clearfix,
.inner-spacer:after,
.jarviswidget,
.jarviswidget-editbox,
.jarviswidget > div {
  zoom: 1;
}
.jarviswidget > div {
  background-color: #ffffff !important;
}
.jarviswidget > div {
  padding: 13px 10px;
}
.greentext {
  color: #8B9635 !important;
}
.bluetext {
  color: #006589 !important;
}
.create-contact .custom-radio.custom-control {
  display: inline-block !important;
}
.create-contact .custom-radio .custom-control-label {
  margin-right: 20px;
}
.create-contact .custom-radio .custom-control-label::before {
  border-radius: 50%;
  margin-top: 3px;
}
.create-contact .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none !important;
}
.create-contact .custom-checkbox .custom-control-label::before {
  width: 1.2rem;
  height: 1.3rem;
  top: 0.5rem;
}
.create-contact .custom-checkbox .custom-control-label::after {
  top: 0.4rem;
  left: -1.7rem;
  width: 1.5rem;
  height: 1.5rem;
}
.create-contact .form-group label {
  font-size: 14px;
}
.create-contact .form-group textarea.form-control {
  min-height: 140px !important;
  height: auto !important;
  resize: none;
}
.create-contact .invalid-feedback {
  font-size: 14px;
}
.form-control.is-invalid {
  background: none !important;
}
#header.colorChangeTest {
  background-color: #474d226e !important;
}
.panelSector .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  position: relative;
  top: 0px;
}
.panelSector .ng-select.ng-select-multiple.requiredSection .ng-select-container {
  border: 1px solid #dc3545 !important;
}
.panelSector .ng-select.ng-select-multiple.requiredSection .ng-select-container .ng-value-container .ng-placeholder {
  position: relative;
  top: 0px;
}
.cookie-text {
  font-size: 12px;
}
.show-consent {
  display: flex;
  display: -webkit-flex;
}
.cookie-consent {
  width: 100%;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: #d6d6d6;
  background-color: #3c404d;
  padding: 0.6em 1.8em;
  z-index: 9999;
  padding: 10px 0 0 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.consent-btn {
  color: #000;
  flex: 1 0 130px;
  background-color: #8B9635;
  border-color: transparent;
  padding: 0.2em 0.5em;
  font-size: 1.2em;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-weight: bolder;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  border-radius: 5px;
  min-height: 20px;
  margin: 0 30px 10px 10px;
  max-width: 100px;
}
.priUrl {
  color: #8B9635 !important;
  text-decoration: none;
  font-weight: 500;
}
a {
  color: #8B9635;
  text-decoration: none;
  font-weight: 500;
}
a:hover {
  color: #8B9635;
}
a:active {
  color: #8B9635;
}
table thead tr th {
  font-size: 14px;
  font-weight: 400;
}
.font-size-16 {
  font-size: 16px !important;
}
.smart-style-1 .selected .commonMergeBtn {
  color: #000 !important;
}
.smart-style-1 .selected .commonMergeBtn:hover {
  border: 1px solid #000 !important;
  color: #000 !important;
}
@media (max-width: 948px) {
  span.theme-header-text-color.language-text {
    display: none !important;
  }

  li.dropdown {
    width: 45px !important;
  }

  #logo {
    width: 100px !important;
  }
}
@media (min-width: 283px) and (max-width: 332px) {
  .collapseMenu.menu-left-item {
    margin-right: 50px !important;
  }
}
@media (max-width: 331px) {
  .menu-left-item.topMenuLeft.menuSelected {
    margin-left: 20px !important;
  }
}
.user-bar {
  margin-left: -10px;
  /* margin-top: 10px; */
}
div#DataTables_Table_2_wrapper {
  overflow: auto !important;
}
@media (max-width: 660px) {
  .footer-menu {
    padding: 8px 4px 3px 10px !important;
    color: #ffffff !important;
  }
}
@media (max-width: 805px) {
  .navFooter {
    position: initial !important;
  }
}
.smart-style-1 .iconForm {
  color: #ffffff !important;
}
@media (max-width: 670px) {
  .commonStyleFilter {
    flex: 1 0 90%;
    margin: 0px 6px;
    display: grid;
  }
}
.userDash_tileHead,
.clickLbl {
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
}
.userDash_tileHeadgreen,
.clickLblgreen {
  color: #ffffff;
  font-weight: 500;
}
.smart-style-1 .tile-formbtn {
  background-color: unset !important;
  border: 2px solid #8B9635 !important;
}
.smart-style-1 .tile-formbtn:hover {
  background-color: #8B9635 !important;
  color: #171717 !important;
}
.smart-style-2 .tile-formbtn {
  background-color: unset !important;
  color: #ffffff !important;
  border: 2px solid #8B9635 !important;
}
.smart-style-2 .tile-formbtn:hover {
  background-color: #8B9635 !important;
}
.smart-style-1 .lstTrncnTbl thead tr th {
  background-color: #1e1e1e !important;
  color: #fff !important;
  border-color: #333 !important;
}
.codePopup {
  width: 1500px !important;
}
.jarviswidget {
  position: static !important;
}
.checkbox-container input:checked ~ .checkmark {
  background-color: transparent;
}
input.row-checkbox {
  height: 17px !important;
  width: 17px !important;
  accent-color: #8B9635 !important;
}
.checkmark {
  position: absolute;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 7px;
  background-color: transparent;
}
/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: transparent;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
